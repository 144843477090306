import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET: {
    URL: "orgs/profile/:id/",
    METHOD: "GET",
  },
  GET_ALL: {
    URL: "orgs/profiles/",
    METHOD: "POST",
  },
  GET_FARMS: {
    URL: "orgs/profile/:id/farms",
    METHOD: "GET",
  },
  GET_DUTY_SESSIONS: {
    URL: "orgs/profile/:id/duty-sessions",
    METHOD: "GET",
  },
  GET_PAYMENTS: {
    URL: "orgs/profile/:id/payments",
    METHOD: "GET",
  },
  TOGGLE_USER_STATUS: {
    URL: "orgs/profile/:id/toggle-user-status",
    METHOD: "GET",
  },
  RE_ASSIGN: {
    URL: "orgs/profiles/re-assign/",
    METHOD: "POST",
  },
  PROMOTE_EXECUTIVE: {
    URL: "orgs/profiles/promote-agent/",
    METHOD: "POST",
  },
  GET_ALL_MANAGERS: {
    URL: "orgs/managers/",
    METHOD: "POST",
  },
  GET_EXECUTIVE_PAYMENTS: {
    URL: "orgs/payment-details/:id",
    METHOD: "GET",
  },
};

const profileApi = {
  promoteExecutive: async (executives: any[]) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.PROMOTE_EXECUTIVE,
      options: {
        data: {
          agents: executives,
        },
      },
    });

    return response;
  },
  reAssign: async (reassignPayload: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.RE_ASSIGN,
      options: {
        data: {
          ...reassignPayload,
        },
      },
    });

    return response;
  },
  getAllProfiles: async (pagination: object, dataToBeSend: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...dataToBeSend,
        },
      },
    });

    return response;
  },
  getExecutivePayments: async (id: number, pagination: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_EXECUTIVE_PAYMENTS,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          ...pagination,
        },
      },
    });

    return response;
  },
  toggleUserStatus: async (userId: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.TOGGLE_USER_STATUS,
      options: {
        pathVars: {
          id: userId,
        },
      },
    });

    return response;
  },
  getProfileDetails: async (userId: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET,
      options: {
        pathVars: {
          id: userId,
        },
      },
    });

    return response;
  },
  getManagers: async (pagination: object, filterData: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_MANAGERS,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...filterData,
        },
      },
    });
    return response;
  },
  getDutySession: async (userId: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_DUTY_SESSIONS,
      options: {
        pathVars: {
          id: userId,
        },
      },
    });

    return response;
  },
  getPayment: async (userId: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_PAYMENTS,
      options: {
        pathVars: {
          id: userId,
        },
      },
    });

    return response;
  },
  getFarms: async (userId: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_FARMS,
      options: {
        pathVars: {
          id: userId,
        },
      },
    });

    return response;
  },
};

export default profileApi;
