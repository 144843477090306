import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_CROPS: ApiResources = {
  GET_CROPS: {
    URL: "orgs/crops/",
    METHOD: "POST",
  },
  GET_CROP_DETAILS: {
    URL: "orgs/crop/:id/",
    METHOD: "GET",
  },
};

const cropApi = {
  getAllCrops: async (
    pagination: object,
    payload: object
  ) => {
    const response = await kyApiService({
      resource: API_CROPS.GET_CROPS,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  getCropDetails: async (cropId: number) => {
    const response = await kyApiService({
      resource: API_CROPS.GET_CROP_DETAILS,
      options: {
        pathVars: {
          id: cropId,
        },
      },
    });

    return response;
  },
};

export default cropApi;
