import kyApiService from "@/infra/plugins/axios";
import { SuperDashboard } from "@/types/api/dashboard";
import { ApiResources } from "@/types/axios";

const API_ENDPOINTS: ApiResources = {
  GET_DASHBOARD_DETAILS: {
    URL: "orgs/super-dashboard/",
    METHOD: "GET",
  },
  GET_ALL_ROLES: {
    URL: "orgs/roles/",
    METHOD: "GET",
  },
};

const dashboardApi: SuperDashboard = {
  getDashboardDetails: async (params: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_DASHBOARD_DETAILS,
      options: {
        queryParams: {
          ...params
        },
      },
    });

    return response;
  },
  getRoles: async () => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_ROLES,
    });

    return response;
  },
};

export default dashboardApi;
