import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";
import { GetPurchasesPayload } from "@/types/api/purchase";

const API_ENDPOINTS: ApiResources = {
  GET_ALL: {
    URL: "orgs/purchases/",
    METHOD: "POST",
  },
  GET: {
    URL: "orgs/purchase/:id/",
    METHOD: "GET",
  },
  GET_PURCHASE_ORDERS: {
    URL: "orgs/purchase-orders/",
    METHOD: "POST",
  },
  GET_PURCHASE_ORDER_DETAILS: {
    URL: "orgs/purchase/order/:id",
    METHOD: "GET",
  },
  GET_OUTSTANDING_BALANCE: {
    URL: "orgs/farmer/outstanding-balance/:id/",
    METHOD: "GET",
  },
};

const purchaseApi = {
  getPurchases: async (queryParams: GetPurchasesPayload, payload: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL,
      options: {
        queryParams: { ...queryParams },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  getPurchaseOrders: async (payload: any, filterPayload: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_PURCHASE_ORDERS,
      options: {
        queryParams: { ...payload },
        data: {
          ...filterPayload,
        },
      },
    });

    return response;
  },
  getPurchaseDetails: async (id: string, orgId: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          org_id: orgId,
        },
      },
    });

    return response;
  },
  getPurchaseOrderDetails: async (id: string, orgId: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_PURCHASE_ORDER_DETAILS,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          org_id: orgId || "",
        },
      },
    });

    return response;
  },

  getOutstandingBalance: async (id: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_OUTSTANDING_BALANCE,
      options: {
        pathVars: {
          id,
        },
      },
    });

    return response;
  },
};

export default purchaseApi;
