import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL_CUSTOMERS: {
    URL: "orgs/buyer/customers",
    METHOD: "POST",
  },
  GET_CUSTOMER: {
    URL: "orgs/buyer/customer/:id/",
    METHOD: "GET",
  },
  CHANGE_KYC_STATUS: {
    URL: "orgs/buyer/kyc/:id/",
    METHOD: "POST",
  },
  GET_ALL_REQUIREMENTS: {
    URL: "orgs/buyer/requirements",
    METHOD: "POST",
  },
  GET_REQUIREMENT: {
    URL: "orgs/buyer/requirement/:id/",
    METHOD: "GET",
  },
  GET_INTERESTS: {
    URL: "orgs/buyer/interests",
    METHOD: "POST",
  },
  GET_PACKAGING: {
    URL: "orgs/packagings",
    METHOD: "GET",
  },
  ADD_PACKAGING: {
    URL: "orgs/packagings",
    METHOD: "POST",
  },
};

const buyerApi = {
  getRequirements: async (pagination: object, payload?: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_REQUIREMENTS,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  getInterests: async (pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_INTERESTS,
      options: {
        queryParams: {
          ...pagination,
        },
      },
    });

    return response;
  },
  getRequirementDetails: async (requirementId: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_REQUIREMENT,
      options: {
        pathVars: {
          id: requirementId,
        },
      },
    });

    return response;
  },
  getBuyersCustomers: async (filterData: object, pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_CUSTOMERS,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...filterData,
        },
      },
    });

    return response;
  },
  getBuyersCustomerDetails: async (customerId: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_CUSTOMER,
      options: {
        pathVars: {
          id: customerId,
        },
      },
    });

    return response;
  },
  changeKycStatus: async (kycId: any, payload: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CHANGE_KYC_STATUS,
      options: {
        pathVars: {
          id: kycId,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  getPackaging: async (pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_PACKAGING,
      options: {
        queryParams: {
          ...pagination,
        },
      },
    });

    return response;
  },
  addPackaging: async (payload: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.ADD_PACKAGING,
      options: {
        data: payload,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },
};

export default buyerApi;
