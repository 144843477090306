
import { ref, watch } from "vue";
import {
  NForm,
  FormInst,
  FormValidationError,
  useMessage,
  FormRules,
  FormItemRule,
} from "naive-ui";
import Button from "@/views/components/Button/Button.vue";
import ChangePassword from "@/views/pages/auth/ChangePassword.vue";
import Input from "@/views/components/form/Input.vue";
import FormItem from "@/views/components/form/FormItem.vue";
import { storeToRefs } from "pinia";

import { useModalStore } from "@/stores/modal.store";
import { useAuthStore } from "@/stores/auth.store";

interface ModelType {
  email: string | null;
}

export default {
  name: "ForgotPassword",
  components: {
    NForm,
    FormItem,
    Input,
    Button,
  },
  setup() {
    const message = useMessage();
    const modal = useModalStore();
    const authStore = useAuthStore();
    const { openOTPModal } = storeToRefs(authStore);
    const formRef = ref<FormInst | null>(null);
    const modelRef = ref<ModelType>({
      email: null,
    });

    const rules: FormRules = {
      email: [
        {
          required: true,
          trigger: ["blur", "input"],
          validator: (rule: FormItemRule, value: string) => {
            // check if this is valid email using regex
            const emailRegex = new RegExp(
              "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
            );
            if (emailRegex.test(value)) {
              return true;
            } else {
              return new Error("Invalid email");
            }
          },
        },
      ],
    };

    watch(openOTPModal, () => {
      if (openOTPModal.value) {
        modal.open(ChangePassword, {
          title: "Change Password",
          subtitle: "Change Password",
          props: {
            userEmail: modelRef.value.email
          }
        });
      }
    });

    const submitForm = () => {
      formRef.value?.validate(
        (errors: Array<FormValidationError> | undefined) => {
          if (!errors) {
            if (modelRef.value.email) {
              authStore.getOTP_OnMail(modelRef.value.email as string);
            }
          } else {
            console.log(errors);
            message.error("Invalid Data");
          }
        }
      );
    };

    return {
      model: modelRef,
      rules,
      formRef,
      submitForm,
    };
  },
};
