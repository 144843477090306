
import { useRouter } from "vue-router";
import { onMounted, ref, watch } from "vue";
import {
  FormInst,
  FormValidationError,
  useMessage,
  FormRules,
  FormItemRule,
  NForm,
} from "naive-ui";

import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth.store";
import Button from "@/views/components/Button/Button.vue";
import Input from "@/views/components/form/Input.vue";
import FormItem from "@/views/components/form/FormItem.vue";
import ForgotPassword from "./ForgotPassword.vue";
import Card from "@/views/components/Card.vue";

interface ModelType {
  email: string | null;
  password: string | null;
}

export default {
  name: "LoginPage",
  components: {
    Button,
    Input,
    FormItem,
    NForm,
    ForgotPassword,
  },

  setup() {
    const formRef = ref<FormInst | null>(null);
    const message = useMessage();
    const router = useRouter();
    const authStore = useAuthStore();
    const { isAuthenticated, loading } = storeToRefs(authStore);
    const modelRef = ref<ModelType>({
      email: null,
      password: null,
    });
    const renderForgotPasswordScreen = ref(false);

    watch(isAuthenticated, (val) => {
      if (val) {
        router.push("/");
      }
    });

    onMounted(() => {
      if (isAuthenticated.value) {
        router.push("/");
      }
    });

    const handleForgotPassword = (state) => {
      renderForgotPasswordScreen.value = state;
    };

    const rules: FormRules = {
      email: [
        {
          required: true,
          trigger: ["blur", "input"],
          validator: (rule: FormItemRule, value: string) => {
            // check if this is valid email using regex
            const emailRegex = new RegExp(
              "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
            );
            if (emailRegex.test(value)) {
              return true;
            } else {
              return new Error("Invalid email");
            }
          },
        },
      ],
      password: [
        {
          required: true,
          message: "Password is required",
          trigger: ["blur", "input"],
        },
      ],
    };

    return {
      formRef,
      model: modelRef,
      handleLogin(e: MouseEvent) {
        e.preventDefault();
        formRef.value?.validate(
          (errors: Array<FormValidationError> | undefined) => {
            if (!errors) {
              authStore.login(
                modelRef.value.email as string,
                modelRef.value.password as string
              );
            } else {
              console.log(errors);
              message.error("Invalid Data");
            }
          }
        );
      },
      handleForgotPassword,
      renderForgotPasswordScreen,
      loading: loading,
      rules,
    };
  },
};
