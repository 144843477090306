import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_LEAD: ApiResources = {
  GET_LEAD: {
    URL: "orgs/lead/:id/",
    METHOD: "GET",
  },
  GET_LEAD_POST: {
    URL: "orgs/lead/:id/",
    METHOD: "POST",
  },
  GET_ALL: {
    URL: "orgs/leads/",
    METHOD: "POST",
  },
  GET_ALL_DETAILS: {
    URL: "orgs/all-leads/",
    METHOD: "POST",
  },
  RE_ASSIGN: {
    URL: "orgs/leads/re-assign/",
    METHOD: "POST",
  },
  DELETE: {
    URL: "orgs/lead/:id/",
    METHOD: "DELETE",
  },

  GRADE_QUANTITY: {
    URL: "/orgs/lead/:id/grade/",
    METHOD: "POST",
  },
  APPROVE_HARVEST: {
    URL: "/orgs/lead/:id/harvest/",
    METHOD: "POST",
  },
};

const leadsApi = {
  getLeads: async (pagination: object, dataToBeSent: any) => {
    const response = await kyApiService({
      resource: API_LEAD.GET_ALL,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...dataToBeSent,
        },
      },
    });

    return response;
  },
  reAssignLeads: async (reassignPayload: object) => {
    const response = await kyApiService({
      resource: API_LEAD.RE_ASSIGN,
      options: {
        data: {
          ...reassignPayload,
        },
      },
    });

    return response;
  },
  getLeadDetails: async (params: string | number, id: number) => {
    const response = await kyApiService({
      resource: API_LEAD.GET_LEAD,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          org_id: params,
        },
      },
    });

    return response;
  },
  getLeadDetailsPost: async (params: string | number, id: number, payload?: any) => {
    const response = await kyApiService({
      resource: API_LEAD.GET_LEAD_POST,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          org_id: params,
        },
        data: {
          ...payload
        }
      },
    });

    return response;
  },
  getFarmDetails: async (dataToBeSent: any) => {
    const response = await kyApiService({
      resource: API_LEAD.GET_ALL_DETAILS,
      options: {
        data: {
          ...dataToBeSent,
        },
      },
    });

    return response;
  },
  deleteLead: async (leadId: number) => {
    const response = await kyApiService({
      resource: API_LEAD.DELETE,
      options: {
        pathVars: {
          id: leadId,
        },
      },
    });

    return response;
  },
  gradeQuantity: async (leadId: number, payload: any) => {
    const response = await kyApiService({
      resource: API_LEAD.GRADE_QUANTITY,
      options: {
        pathVars: {
          id: leadId,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  approveHarvest: async (leadId: number, payload: any) => {
    const response = await kyApiService({
      resource: API_LEAD.APPROVE_HARVEST,
      options: {
        pathVars: {
          id: leadId,
        },
        queryParams: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default leadsApi;
