
import { storeToRefs } from "pinia";
import { useModalStore } from "@/stores/modal.store";

export default {
  name: "Commodities fields Errors",
  setup() {
    const modalStore = useModalStore();
    const { props: modalProps } = storeToRefs(modalStore);

    return {
      modalProps,
    };
  },
};
