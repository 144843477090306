import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import { ModulesEnum } from "../config/enums";

const {
  DASHBOARD,
  ORGANISATIONS,
  COMMODITIES,
  HARVEST,
  FARMERS,
  EXECUTIVES,
  LIVETRACKING,
  MANAGERS,
  PURCHASES,
  INVENTORY,
  BULK_PAYMENTS,
  USERS,
  BUYERS_REQUIREMENTS,
  // BUYERS_INTERESTS,
  SALES_CUSTOMERS,
  SALES_INVOICES,
  CONSIGNMENTS,
  TRANSPORTS,
  BUYERS_CUSTOMERS,
  PAYMENTS,
  DEDUCTIONS,
  STOCK_ADJUSTMENTS,
  STOCK_TRANSFERS,
  STOCK_LEDGER,
  WAREHOUSES,
  PROFILE,
  CHART_OF_ACCOUNT,
  REPORTS,
  CROPS,
  ATTRIBUTES,
  DEFINE_PRODUCT,
  PURCHASE_ORDERS,
  PROCESSOR_INVENTORY,
  BRAND_PURCHASE_ORDERS,
  PROCESSOR_CONSIGNMENTS,
  PROCESSOR_CUSTOMERS,
  PROCESSOR_SALES_INVOICES,
  PROCESSOR_REQUIREMENTS,
  BRAND_REQUIREMENTS,
  INSURANCE,
  PACKAGING,
  PESTICIDES,
  FERTILIZERS,
  PACKAGE_OF_PRACTICE,
  COMPLAINT_TICKET,
  JUNIOR_AGRONOMISTS,
  ASSIGNED_PACKAGE_OF_PRACTICE,
  TASKS,
  POP_CROPS,
  GRADE_CONVERTION,
  BATCH_PL,
  ACCOUNT_DELETE_REQUESTS
} = ModulesEnum;

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: DASHBOARD,
    component: () => import("@/views/pages/dashboard/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pages/auth/Login.vue"),
  },
  // {
  //##########################################
  {
    path: "/organizations",
    name: ORGANISATIONS,
    component: () =>
      import("@/views/pages/organization/components/Organizations.vue"),
  },
  // {
  //   path: "/addOrganization",
  //   name: "addOrganization",
  //   component: () =>
  //     import("@/view/pages/organization/forms/CreateOrganization.vue")
  // },
  {
    path: "/organization/:id",
    name: "organization",
    component: () =>
      import("@/views/pages/organization/components/Organization.vue"),
  },
  {
    path: "/commodities",
    name: COMMODITIES,
    component: () =>
      import("@/views/pages/commodity/components/OrgCommodities.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/productdefination",
    name: DEFINE_PRODUCT,
    component: () =>
      import("@/views/pages/defineProduct/components/DefineProduct.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/productdefination/:id",
    name: "product-defination-details",
    component: () =>
      import(
        "@/views/pages/defineProduct/components/ProductDefinationDetails.vue"
      ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/commodity/:id",
    name: "commodity",
    component: () =>
      import("@/views/pages/commodity/components/OrgCommodity.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/variant/:id",
    name: "variant",
    component: () => import("@/views/pages/commodity/components/variant.vue"),
    meta: {
      requiresAuth: false,
    },
    props: true,
  },
  {
    path: "/harvest",
    name: HARVEST,
    component: () => import("@/views/pages/lead/components/OrgLeads.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/attributes",
    name: ATTRIBUTES,
    component: () => import("@/views/pages/attribute/Attributes.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/crops",
    name: CROPS,
    component: () => import("@/views/pages/crop/components/CropsList.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/crops-details/:id",
    name: "crop-details",
    component: () => import("@/views/pages/crop/components/Crops.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: "/crops-details/:id",
  //   name: 'crop-details',
  //   component: () => import("@/views/pages/crop/components/Crops.vue"),
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  {
    path: "/crop/:id",
    name: "crop",
    component: () => import("@/views/pages/crop/components/CropDetails.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/harvest/:id",
    name: "harvest",
    component: () => import("@/views/pages/lead/components/OrgLead.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/profile",
    name: PROFILE,
    component: () => import("@/views/pages/profile/Profile.vue"),
  },
  {
    path: "/farmers",
    name: FARMERS,
    component: () => import("@/views/pages/farmer/components/OrgFarmers.vue"),
  },
  {
    path: "/farmer/:id",
    name: "farmer",
    component: () => import("@/views/pages/farmer/components/OrgFarmer.vue"),
  },
  {
    path: "/executives",
    name: EXECUTIVES,
    component: () =>
      import("@/views/pages/executive/components/OrgExecutives.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/executive/:id",
    name: "executive",
    component: () =>
      import("@/views/pages/executive/components/OrgExecutive.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/executives/livetracking",
    name: LIVETRACKING,
    component: () =>
      import("@/views/pages/executive/components/LiveTracking.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/purchases",
    name: PURCHASES,
    component: () => import("@/views/pages/purchase/component/Purchases.vue"),
  },
  {
    path: "/purchase/:id",
    name: "purchase",
    component: () => import("@/views/pages/purchase/component/Purchase.vue"),
  },
  {
    path: "/purchase-orders",
    name: PURCHASE_ORDERS,
    component: () =>
      import("@/views/pages/purchase/component/PurchaseOrders.vue"),
  },
  {
    path: "/brand-purchase-orders",
    name: BRAND_PURCHASE_ORDERS,
    component: () =>
      import("@/views/pages/purchase/component/PurchaseOrders.vue"),
  },
  {
    path: "/purchase-orders/:id",
    name: "purchase-order-details",
    component: () =>
      import("@/views/pages/purchase/component/PurchaseOrderDetails.vue"),
  },
  {
    path: "/inventory/warehouses",
    name: WAREHOUSES,
    component: () =>
      import("@/views/pages/inventory/components/WareHouses.vue"),
  },
  {
    path: "/inventory/stock-transfers",
    name: STOCK_TRANSFERS,
    component: () =>
      import("@/views/pages/inventory/components/StockTransfers.vue"),
  },
  {
    path: "/inventory/stock-adjustments",
    name: STOCK_ADJUSTMENTS,
    component: () =>
      import("@/views/pages/inventory/components/StockAdjustments.vue"),
  },
  {
    path: "/inventory",
    name: INVENTORY,
    component: () => import("@/views/pages/inventory/components/Items.vue"),
  },
  {
    path: "/inventory/grade_conversion",
    name: GRADE_CONVERTION,
    component: () =>
      import("@/views/pages/inventory/components/GradeConvertion.vue"),
  },
  {
    path: "/processor_inventory",
    name: PROCESSOR_INVENTORY,
    component: () =>
      import("@/views/pages/inventory/components/ProcessorInventory.vue"),
  },
  {
    path: "/processor_inventory/stock-ledger/:id",
    name: "processor_inventory",
    component: () =>
      import(
        "@/views/pages/inventory/components/ProcessorInventoryDetails.vue"
      ),
  },
  {
    path: "/processor/sales-invoice",
    name: PROCESSOR_SALES_INVOICES,
    component: () => import("@/views/pages/sales/components/Invoices.vue"),
  },
  {
    path: "/processor/sales-invoice/:id",
    name: "ProcessorSalesInvoice",
    component: () => import("@/views/pages/sales/components/Invoice.vue"),
  },
  {
    path: "/inventory/stock-ledger/:id",
    name: STOCK_LEDGER,
    component: () =>
      import("@/views/pages/inventory/components/StockLedger.vue"),
  },
  {
    path: "/managers",
    name: MANAGERS,
    component: () => import("@/views/pages/manager/components/Managers.vue"),
  },
  {
    path: "/insurance",
    name: INSURANCE,
    component: () => import("@/views/pages/insurance/component/Insurances.vue"),
  },
  {
    path: "/insurance/:id",
    name: "InsuranceDetails",
    component: () => import("@/views/pages/insurance/component/Insurance.vue"),
  },
  {
    path: "/sales/customers",
    name: SALES_CUSTOMERS,
    component: () => import("@/views/pages/sales/components/Customers.vue"),
  },
  {
    path: "/sales/customer/:id",
    name: "customer",
    component: () => import("@/views/pages/sales/components/Customer.vue"),
  },
  {
    path: "/sales/invoices",
    name: SALES_INVOICES,
    component: () => import("@/views/pages/sales/components/Invoices.vue"),
  },
  {
    path: "/sales/invoice/:id",
    name: "salesInvoice",
    component: () => import("@/views/pages/sales/components/Invoice.vue"),
  },
  {
    path: "/sales/consignments",
    name: CONSIGNMENTS,
    component: () => import("@/views/pages/sales/components/Consignments.vue"),
  },
  {
    path: "/sales/batches",
    name: BATCH_PL,
    component: () => import("@/views/pages/sales/components/BatchwiseP&L.vue"),
  },
  {
    path: "/sales/batches/:id",
    name: "BatchP&L Details",
    component: () =>
      import("@/views/pages/sales/components/BatchwiseP&LDetails.vue"),
  },
  {
    path: "/processor/consignments",
    name: PROCESSOR_CONSIGNMENTS,
    component: () => import("@/views/pages/sales/components/Consignments.vue"),
  },
  {
    path: "/processor/consignment/:id",
    name: "consignment",
    component: () => import("@/views/pages/sales/components/Consignment.vue"),
  },
  {
    path: "/sales/consignment/:id",
    name: "consignment",
    component: () => import("@/views/pages/sales/components/Consignment.vue"),
  },
  {
    path: "/sales/transports",
    name: TRANSPORTS,
    component: () => import("@/views/pages/sales/components/Transports.vue"),
  },
  {
    path: "/processor/customers",
    name: PROCESSOR_CUSTOMERS,
    component: () => import("@/views/pages/buyers/components/Customers.vue"),
  },
  {
    path: "/buyers/customers",
    name: BUYERS_CUSTOMERS,
    component: () => import("@/views/pages/buyers/components/Customers.vue"),
  },
  {
    path: "/buyers/customer/:id/",
    name: "buyerCustomer",
    component: () => import("@/views/pages/buyers/components/Customer.vue"),
  },
  {
    path: "/buyers/requirements",
    name: BUYERS_REQUIREMENTS,
    component: () => import("@/views/pages/buyers/components/Requirements.vue"),
  },
  {
    path: "/buyers/requirements",
    name: BRAND_REQUIREMENTS,
    component: () => import("@/views/pages/buyers/components/Requirements.vue"),
  },
  {
    path: "/buyers/requirements",
    name: PROCESSOR_REQUIREMENTS,
    component: () => import("@/views/pages/buyers/components/Requirements.vue"),
  },
  // {
  //   path: "/buyers/interests",
  //   name: BUYERS_INTERESTS,
  //   component: () => import("@/views/pages/buyers/components/Interests.vue"),
  // },
  {
    path: "/buyers/requirement/:id/",
    name: "buyers_requirement",
    component: () => import("@/views/pages/buyers/components/Requirement.vue"),
  },
  {
    path: "/buyers/packaging",
    name: PACKAGING,
    component: () => import("@/views/pages/buyers/components/Packaging.vue"),
  },
  {
    path: "/accounts/payments",
    name: PAYMENTS,
    component: () => import("@/views/pages/accounts/components/Payments.vue"),
  },
  {
    path: "/accounts/bulk-payment",
    name: BULK_PAYMENTS,
    component: () =>
      import("@/views/pages/accounts/components/BulkPayments.vue"),
  },
  {
    path: "/accounts/deductions",
    name: DEDUCTIONS,
    component: () => import("@/views/pages/accounts/components/Deductions.vue"),
  },
  {
    path: "/accounts/chart",
    name: CHART_OF_ACCOUNT,
    component: () =>
      import("@/views/pages/accounts/components/ChartOfAccounts.vue"),
  },
  {
    path: "/accounts/reports",
    name: REPORTS,
    component: () => import("@/views/pages/accounts/components/Reports.vue"),
  },
  {
    path: "/users",
    name: USERS,
    component: () => import("@/views/pages/user/components/Users.vue"),
  },
  {
    path: "/manager/:id",
    name: "manager",
    component: () => import("@/views/pages/manager/components/Manager.vue"),
  },
  {
    path: "/pesticides",
    name: PESTICIDES,
    component: () => import("@/views/pages/pesticides/Pesticides.vue"),
  },
  {
    path: "/fertilizers",
    name: FERTILIZERS,
    component: () => import("@/views/pages/fertilizers/Fertilizers.vue"),
  },
  {
    path: "/package_of_practice",
    name: PACKAGE_OF_PRACTICE,
    component: () =>
      import("@/views/pages/packageOfPractice/PackageOfPractice.vue"),
  },
  {
    path: "/package_of_practice/:id",
    name: "POP Details",
    component: () => import("@/views/pages/packageOfPractice/POPDetails.vue"),
  },
  {
    path: "/assigned_package_of_practices/:id",
    name: ASSIGNED_PACKAGE_OF_PRACTICE,
    component: () =>
      import("@/views/pages/packageOfPractice/AssignedPOPList.vue"),
  },
  {
    path: "/pop_crops",
    name: POP_CROPS,
    component: () =>
      import("@/views/pages/packageOfPractice/POPAssignedCrops.vue"),
  },
  {
    path: "/assigned_package_of_practice/:id",
    name: "Assigned POP Details",
    component: () =>
      import("@/views/pages/packageOfPractice/AssignedPOPDetails.vue"),
  },
  {
    path: "/complaint_tickets",
    name: COMPLAINT_TICKET,
    component: () =>
      import("@/views/pages/extensionSupport/ComplaintTickets.vue"),
  },
  {
    path: "/complaint_tickets/:id",
    name: "complaintTicketDetails",
    component: () =>
      import("@/views/pages/extensionSupport/ComplaintTicket.vue"),
  },
  {
    path: "/jr_agronomist",
    name: JUNIOR_AGRONOMISTS,
    component: () => import("@/views/pages/extensionSupport/Agronomists.vue"),
  },
  {
    path: "/tasks",
    name: TASKS,
    component: () => import("@/views/pages/task/Tasks.vue"),
  },
  {
    path: "/tasks/:id",
    name: "Task Details",
    component: () => import("@/views/pages/task/Task.vue"),
  },
  {
    path: "/account-delete/",
    name: "DeleteAccountForm",
    component: () =>
      import("@/views/pages/AccountDelete/DeleteAccountForm.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/deleterequests/",
    name: ACCOUNT_DELETE_REQUESTS,
    component: () => import("@/views/pages/AccountDelete/DeleteRequests.vue"),
  },
  // {
  //   path: "/builder",
  //   name: "builder",
  //   component: () => import("@/view/pages/Builder.vue")
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
