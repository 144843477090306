import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL: {
    URL: "orgs/stock-entries/",
    METHOD: "GET",
  },
  CREATE: {
    URL: "orgs/stock-entry/",
    METHOD: "POST",
  },
  GET_AVAILABILITY: {
    URL: "orgs/stock/get-availability/",
    METHOD: "POST",
  },
  SUBMIT_STOCK_ENTRY: {
    URL: "orgs/stock-entry/:id/",
    METHOD: "PATCH",
  },
  DELETE_STOCK_ENTRY: {
    URL: "orgs/stock-entry/:id/",
    METHOD: "DELETE",
  },
};

const stockEntryApi = {
  getStocks: async (pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL,
      options: {
        queryParams: {
          ...pagination,
          stock_entry_type: "Material Transfer",
        },
      },
    });
    return response;
  },
  getWarehouseDetails: async (warehouseId: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET,
      options: {
        pathVars: {
          id: warehouseId,
        },
      },
    });
    return response;
  },
  createStockEntry: async (stockEntryData: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE,
      options: {
        data: {
          ...stockEntryData,
        },
      },
    });
    return response;
  },
  getStockAvailability: async (batchNo: string, warehouse: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_AVAILABILITY,
      options: {
        data: {
          batch_no: batchNo,
          warehouse: warehouse,
        },
      },
    });
    return response;
  },
  submitStockEntry: async (params: any, id: string | number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.SUBMIT_STOCK_ENTRY,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          ...params,
        },
      },
    });
    return response;
  },
  deleteStockEntry: async (params: any, id: string | number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.DELETE_STOCK_ENTRY,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          ...params,
        },
      },
    });
    return response;
  },
};

export default stockEntryApi;
