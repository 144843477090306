import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL_CUSTOMERS: {
    URL: "orgs/sales/customers",
    METHOD: "POST",
  },
  CREATE_CUSTOMER: {
    URL: "orgs/sales/customer",
    METHOD: "POST",
  },
  GET_CUSTOMER: {
    URL: "orgs/sales/customer/:id/",
    METHOD: "GET",
  },
  GET_PAYMENT_TERMS: {
    URL: "orgs/sales/payment-terms",
    METHOD: "GET",
  },
  GET_ALL_INVOICES: {
    URL: "orgs/sales/invoices",
    METHOD: "POST",
  },
  CREATE_INVOICE: {
    URL: "orgs/sales/invoice",
    METHOD: "POST",
  },
  SUBMIT_INVOICE: {
    URL: "orgs/sales/invoice/:id/",
    METHOD: "PATCH",
  },
  DELETE_INVOICE: {
    URL: "orgs/sales/invoice/:id/",
    METHOD: "DELETE",
  },
  GET_INVOICE: {
    URL: "orgs/sales/invoice/:id/",
    METHOD: "GET",
  },
  GET_PROCESSORS: {
    URL: "orgs/processors",
    METHOD: "GET",
  },
  IMPORT_CUSTOMER: {
    URL: "orgs/import/sales/customer/:id/",
    METHOD: "POST",
  },
  GET_BRAND_SALES_INVOICE: {
    URL: "orgs/brand/sales/invoices/",
    METHOD: "POST",
  },
};

const salesApi = {
  getCustomers: async (pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_CUSTOMERS,
      options: {
        queryParams: {
          ...pagination,
          expand: 1,
        },
      },
    });

    return response;
  },
  getProcessors: async (orgId: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_PROCESSORS,
      options: {
        queryParams: {
          org_id: orgId,
        },
      },
    });

    return response;
  },
  createCustomer: async (customerData: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE_CUSTOMER,
      options: {
        data: {
          ...customerData,
        },
      },
    });

    return response;
  },
  importSalesCustomer: async (processorID: number, orgId: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.IMPORT_CUSTOMER,
      options: {
        pathVars: {
          id: processorID,
        },
        queryParams: {
          org_id: orgId,
        },
      },
    });

    return response;
  },
  getInvoices: async (invoiceData: object, pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_INVOICES,
      options: {
        data: {
          ...invoiceData,
        },
        queryParams: {
          ...pagination,
        },
      },
    });

    return response;
  },
  getBrandSalesInvoice: async (invoiceData: object, pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_BRAND_SALES_INVOICE,
      options: {
        data: {
          ...invoiceData,
        },
        queryParams: {
          ...pagination,
        },
      },
    });

    return response;
  },
  getCustomerDetails: async (customerId: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_CUSTOMER,
      options: {
        pathVars: {
          id: customerId,
        },
      },
    });

    return response;
  },
  getInvoiceDetails: async (invoiceId: string, payload?: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_INVOICE,
      options: {
        pathVars: {
          id: invoiceId,
        },
        queryParams: {
          expand: 1,
          ...payload
        },
      },
    });

    return response;
  },
  deleteInvoice: async (invoiceId: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.DELETE_INVOICE,
      options: {
        pathVars: {
          id: invoiceId,
        },
      },
    });

    return response;
  },
  submitInvoice: async (invoiceId: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.SUBMIT_INVOICE,
      options: {
        pathVars: {
          id: invoiceId,
        },
      },
    });

    return response;
  },
  createInvoice: async (invoiceData: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE_INVOICE,
      options: {
        data: {
          ...invoiceData,
        },
      },
    });

    return response;
  },
  getPaymentTerms: async () => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_PAYMENT_TERMS,
    });

    return response;
  },
};

export default salesApi;
