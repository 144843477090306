import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_menu = _resolveComponent("n-menu")!
  const _component_n_layout_sider = _resolveComponent("n-layout-sider")!

  return (_openBlock(), _createBlock(_component_n_layout_sider, {
    "collapse-mode": "width",
    "collapsed-width": 64,
    width: 256,
    collapsed: $setup.collapsed,
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => ($setup.collapsed = false)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => ($setup.collapsed = true))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_menu, {
        collapsed: $setup.collapsed,
        options: $setup.menuOptionsRef
      }, null, 8, ["collapsed", "options"])
    ]),
    _: 1
  }, 8, ["collapsed"]))
}