import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, {
    show: $setup.isOpen,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (($setup.isOpen) = $event)),
    class: "custom-card",
    style: _normalizeStyle($setup.styleProps || $setup.bodyStyle),
    preset: "card",
    title: $setup.title,
    size: "huge"
  }, {
    action: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.actions, (action) => {
        return (_openBlock(), _createBlock(_component_Button, {
          class: "mr-2",
          key: action.label,
          type: action.type,
          onClick: ($event: any) => (action.onClick($setup.model)),
          loading: action?.loading,
          disabled: action?.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(action.label), 1)
          ]),
          _: 2
        }, 1032, ["type", "onClick", "loading", "disabled"]))
      }), 128))
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.view), {
        modelValue: $setup.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event))
      }, null, 8, ["modelValue"]))
    ]),
    _: 1
  }, 8, ["show", "style", "title"]))
}