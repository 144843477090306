
import { defineComponent } from "vue";

export default defineComponent({
  name: "DashboardIcon",
  setup() {
    return {
      DashboardIcon: () => import("@/assets/icons/DashboardIcon.vue"),
    };
  },
});
