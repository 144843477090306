import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_REPORT: {
    URL: "orgs/report",
    METHOD: "POST",
  },
}

const reportApi = {
  getReports: async (filterData: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_REPORT,
      options: {
        data: {
          ...filterData,
        },
      },
    });

    return response;
  },
};

export default reportApi;
