import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL: {
    URL: "orgs/users/",
    METHOD: "GET",
  },
  CREATE_USER: {
    URL: "orgs/user/",
    METHOD: "POST",
  },
  FILTER_NAMES: {
    URL: "orgs/users/filter-names/",
    METHOD: "GET",
  },
  UPDATE_USER: {
    URL: "orgs/user/",
    METHOD: "PUT",
  },
  GET_DETAILS: {
    URL: "/orgs/user/:id/",
    METHOD: "GET",
  },
  DELETE_USER: {
    URL: "/orgs/user/:id/",
    METHOD: "DELETE",
  },
  USER_STATUS: {
    URL: "orgs/user/status/:id/",
    METHOD: "PUT",
  },
};

const userApi = {
  getAllUsers: async (params: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL,
      options: {
        queryParams: {
          ...params,
        },
      },
    });

    return response;
  },
  deleteUser: async (id: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.DELETE_USER,
      options: {
        pathVars: {
          id,
        },
      },
    });

    return response;
  },
  createUser: async (form: object, editUser?: boolean, params?: any) => {
    if (editUser) {
      const response = await kyApiService({
        resource: API_ENDPOINTS.UPDATE_USER,
        options: {
          data: {
            ...form,
          },
          queryParams: {
            ...params
          }
        },
      });
      return response;
    } else {
      const response = await kyApiService({
        resource: API_ENDPOINTS.CREATE_USER,
        options: {
          data: {
            ...form,
          },
        },
      });

      return response;
    }
  },
  getUserDetails: async (id: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_DETAILS,
      options: {
        pathVars: {
          id,
        },
      },
    });

    return response;
  },
  userStatus: async (params: any, payload: any, id: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.USER_STATUS,
      options: {
        queryParams: {
          ...params,
        },
        data: {
          ...payload
        },
        pathVars: {
          id
        }
      },
    });

    return response;
  },
};

export default userApi;
