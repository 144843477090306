import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";
import { PaymentsPayload } from "@/types/api/payments";

const API_ENDPOINTS: ApiResources = {
  GET_ALL: {
    URL: "orgs/payments",
    METHOD: "GET",
  },
  CREATE: {
    URL: "orgs/payment",
    METHOD: "POST",
  },
  GET_MODES_OF_PAYMENT: {
    URL: "orgs/mode-of-payments/",
    METHOD: "GET",
  },
  MAKE_PAYMENT: {
    URL: "orgs/make-payment/:id/",
    METHOD: "POST",
  },
};

const paymentApi = {
  getPayments: async (filterOptions: PaymentsPayload) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL,
      options: {
        queryParams: {
          ...filterOptions
        },
      },
    });

    return response;
  },
  getModesOfPayment: async () => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_MODES_OF_PAYMENT,
    });

    return response;
  },
  makePayment: async (paymentFormData: object, leadId: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.MAKE_PAYMENT,
      options: {
        data: {
          ...paymentFormData,
        },
        pathVars: {
          id: leadId,
        },
      },
    });

    return response;
  },
  createPayment: async (paymentFormData: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE,
      options: {
        data: {
          ...paymentFormData,
          payment_type: "Internal Transfer",
        },
      },
    });

    return response;
  },
};

export default paymentApi;
