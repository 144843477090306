import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL: {
    URL: "orgs/stock-adjustments/",
    METHOD: "GET",
  },
  GET_BATCHES: {
    URL: "orgs/batches/",
    METHOD: "GET",
  },
  CREATE: {
    URL: "orgs/stock-adjustment/",
    METHOD: "POST",
  },
  DELETE: {
    URL: "orgs/stock-adjustment/:id/",
    METHOD: "DELETE",
  },
  SUBMIT: {
    URL: "orgs/stock-adjustment/:id/",
    METHOD: "PATCH",
  },
};

const stockAdjustmentApi = {
  getStockAdjustments: async (pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL,
      options: {
        queryParams: {
          ...pagination,
        },
      },
    });
    return response;
  },
  submitStockAdjustment: async (id: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.SUBMIT,
      options: {
        pathVars: {
          id,
        },
      },
    });
    return response;
  },
  deleteStockAdjustment: async (id: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.DELETE,
      options: {
        pathVars: {
          id,
        },
      },
    });
    return response;
  },
  createStockAdjustment: async (formData: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE,
      options: {
        data: {
          ...formData,
        },
      },
    });
    return response;
  },
  getBatches: async (itemCode: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_BATCHES,
      options: {
        queryParams: {
          item_code: itemCode,
        },
      },
    });
    return response;
  },
};

export default stockAdjustmentApi;
