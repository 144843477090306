import { RolesEnum, ModulesEnum, PermissionIdEnum } from "../enums";

const {
  SUPER_ADMIN,
  ORG_ADMIN,
  WAREHOUSE_MANAGER,
  SALES_MANAGER,
  ACCOUNTS_MANAGER,
  ENGAGEMENT_MANAGER,
  PROCUREMENT_MANAGER,
  JUNIOR_AGRONOMIST,
  SENIOR_AGRONOMIST,
} = RolesEnum;
const {
  DASHBOARD,
  ORGANISATIONS,
  COMMODITIES,
  HARVEST,
  FARMERS,
  EXECUTIVES,
  MANAGERS,
  PURCHASES,
  INVENTORY,
  SALES,
  ACCOUNTS,
  USERS,
  BUYERS,
  BUYERS_CUSTOMERS,
  BUYERS_REQUIREMENTS,
  CONSIGNMENTS,
  // BUYERS_INTERESTS,
  PROFILE,
  TRANSPORTS,
  PURCHASE_ORDERS,
  COMPLAINT_TICKET,
  CROP_NUTRITION,
  PACKAGE_OF_PRACTICE,
  TASKS,
  ACCOUNT_DELETE_REQUESTS
} = ModulesEnum;
const {
  CREATE_NEW_ORGANISATION,
  EDIT_ORGANISATION,
  ADD_COMMODITY,
  VISIT_ERP_SITE,
  ADD_CDMS,
} = PermissionIdEnum[ORGANISATIONS];
const {
  ADD_COMMODITY: ADD_COMMODITY_COM,
  ADD_VARIANT,
  CREATE_NEW_QUESTION,
  ADD_FROM_QUESTION_BANK,
  DELETE_QUESTION,
  CREATE_NEW_COMMODITY,
} = PermissionIdEnum[COMMODITIES];
const {
  CREATE_NEW_USER,
  DOWNLOAD_CSV: DOWNLOAD_CSV_LEADS,
  REASSIGN_LEADS,
  DELETE_LEAD: DELETE_LEAD_LEADS,
  SUBMIT_HARVEST,
} = PermissionIdEnum[HARVEST];
const {
  PROMOTE_EXECUTIVES,
  REASSIGN_EXECUTIVES,
  DOWNLOAD_CSV: DOWNLOAD_CSV_EXECUTIVES,
  ACTIVATE_EXECUTIVE,
  DEACTIVATE_EXECUTIVE,
  DELETE_LEAD: DELETE_LEAD_EXECUTIVES,
} = PermissionIdEnum[EXECUTIVES];
const { DOWNLOAD_CSV, DELETE_HARVEST } = PermissionIdEnum[FARMERS];
const {
  DELETE_STOCK_ADJUSTMENT,
  CREATE_STOCK_ADJUSTMENT,
  CREATE_STOCK_TRANSFER,
  CREATE_WAREHOUSE,
  EDIT_WAREHOUSE,
  SUBMIT_STOCK_ADJUSTMENT,
  DOWNLOAD_CSV_INVENTORY,
} = PermissionIdEnum[INVENTORY];
const {
  CREATE_CUSTOMER,
  CREATE_SALES_INVOICE,
  ADD_TRANSPORT,
  SUBMIT_SALES_INVOICE,
  EDIT_CUSTOMER,
  DOWNLOAD_CSV_TRANSPORT: SALES_TRANSPORT_CSV,
  DOWNLOAD_CSV_INVOICE,
} = PermissionIdEnum[SALES];
const {
  TRANSFER_FUNDS_INTERNALLY,
  UPLOAD_BULK_PAYMENT,
  DOWNLOAD_BULK_PAYMENTS,
} = PermissionIdEnum[ACCOUNTS];
const { PAY_NOW, DOWNLOAD_CSV_PURCHASE } = PermissionIdEnum[PURCHASES];
const { CREATE_CONSIGNMENT, DOWNLOAD_CSV_CONSIGNMENTS } =
  PermissionIdEnum[CONSIGNMENTS];
const { DOWNLOAD_CSV_TRANSPORT } = PermissionIdEnum[TRANSPORTS];
const { DOWNLOAD_CSV_PO } = PermissionIdEnum[PURCHASE_ORDERS];
const {
  ASSIGN_TICKET,
  CHANGE_TICKET_STATUS,
  COMMENT_ON_TICKET,
  RESPOND_TO_TICKET,
} = PermissionIdEnum[COMPLAINT_TICKET];

// View access defined in contstants.js -> Menu Config
const ModulePermissions = Object.freeze({
  [SUPER_ADMIN]: {
    [DASHBOARD]: [],
    [ORGANISATIONS]: [
      CREATE_NEW_ORGANISATION,
      EDIT_ORGANISATION,
      ADD_COMMODITY,
      VISIT_ERP_SITE,
      ADD_CDMS,
    ],
    [COMMODITIES]: [
      ADD_COMMODITY_COM,
      ADD_VARIANT,
      CREATE_NEW_QUESTION,
      ADD_FROM_QUESTION_BANK,
      DELETE_QUESTION,
      CREATE_NEW_COMMODITY,
    ],
    [HARVEST]: [
      DOWNLOAD_CSV_LEADS,
      REASSIGN_LEADS,
      DELETE_LEAD_LEADS,
      SUBMIT_HARVEST,
    ],
    [FARMERS]: [DOWNLOAD_CSV, DELETE_HARVEST],
    [EXECUTIVES]: [
      PROMOTE_EXECUTIVES,
      REASSIGN_EXECUTIVES,
      DOWNLOAD_CSV_EXECUTIVES,
      ACTIVATE_EXECUTIVE,
      DEACTIVATE_EXECUTIVE,
      DELETE_LEAD_EXECUTIVES,
    ],
    [MANAGERS]: [],
    [PURCHASES]: [PAY_NOW, DOWNLOAD_CSV_PURCHASE],
    [INVENTORY]: [
      DELETE_STOCK_ADJUSTMENT,
      CREATE_STOCK_ADJUSTMENT,
      CREATE_STOCK_TRANSFER,
      CREATE_WAREHOUSE,
      EDIT_WAREHOUSE,
      SUBMIT_STOCK_ADJUSTMENT,
      DOWNLOAD_CSV_INVENTORY,
    ],
    [SALES]: [
      CREATE_CUSTOMER,
      CREATE_SALES_INVOICE,
      ADD_TRANSPORT,
      SUBMIT_SALES_INVOICE,
      EDIT_CUSTOMER,
      SALES_TRANSPORT_CSV,
      DOWNLOAD_CSV_INVOICE,
    ],
    [CONSIGNMENTS]: [DOWNLOAD_CSV_CONSIGNMENTS, CREATE_CONSIGNMENT],
    [ACCOUNTS]: [
      TRANSFER_FUNDS_INTERNALLY,
      UPLOAD_BULK_PAYMENT,
      DOWNLOAD_BULK_PAYMENTS,
    ],
    [USERS]: [],
    [BUYERS]: [DOWNLOAD_CSV_PO],
    [BUYERS_CUSTOMERS]: [],
    [BUYERS_REQUIREMENTS]: [],
    // [BUYERS_INTERESTS]: [],
    [PROFILE]: [],
  },
  [ORG_ADMIN]: {
    [DASHBOARD]: [],
    [ORGANISATIONS]: [
      CREATE_NEW_ORGANISATION,
      EDIT_ORGANISATION,
      ADD_COMMODITY,
      VISIT_ERP_SITE,
      ADD_CDMS,
    ],
    [COMMODITIES]: [
      ADD_COMMODITY_COM,
      ADD_VARIANT,
      CREATE_NEW_QUESTION,
      ADD_FROM_QUESTION_BANK,
      DELETE_QUESTION,
    ],
    [HARVEST]: [
      CREATE_NEW_USER,
      REASSIGN_LEADS,
      DOWNLOAD_CSV_LEADS,
      DELETE_LEAD_LEADS,
      SUBMIT_HARVEST,
    ],
    [FARMERS]: [DOWNLOAD_CSV, DELETE_HARVEST],
    [EXECUTIVES]: [
      PROMOTE_EXECUTIVES,
      REASSIGN_EXECUTIVES,
      DOWNLOAD_CSV_EXECUTIVES,
      ACTIVATE_EXECUTIVE,
      DEACTIVATE_EXECUTIVE,
      DELETE_LEAD_EXECUTIVES,
    ],
    [MANAGERS]: [DOWNLOAD_CSV],
    [ACCOUNT_DELETE_REQUESTS]:[],
    [PURCHASES]: [PAY_NOW, DOWNLOAD_CSV_PURCHASE],
    [INVENTORY]: [
      DELETE_STOCK_ADJUSTMENT,
      CREATE_STOCK_ADJUSTMENT,
      CREATE_STOCK_TRANSFER,
      CREATE_WAREHOUSE,
      EDIT_WAREHOUSE,
      SUBMIT_STOCK_ADJUSTMENT,
      DOWNLOAD_CSV_INVENTORY,
    ],
    [SALES]: [
      CREATE_CUSTOMER,
      CREATE_SALES_INVOICE,
      ADD_TRANSPORT,
      SUBMIT_SALES_INVOICE,
      EDIT_CUSTOMER,
      SALES_TRANSPORT_CSV,
      DOWNLOAD_CSV_INVOICE,
    ],
    [CONSIGNMENTS]: [DOWNLOAD_CSV_CONSIGNMENTS, CREATE_CONSIGNMENT],
    [ACCOUNTS]: [
      TRANSFER_FUNDS_INTERNALLY,
      UPLOAD_BULK_PAYMENT,
      DOWNLOAD_BULK_PAYMENTS,
    ],
    [USERS]: [],
    [BUYERS]: [DOWNLOAD_CSV_PO],
    [BUYERS_CUSTOMERS]: [],
    [BUYERS_REQUIREMENTS]: [],
    [COMPLAINT_TICKET]: [
      COMMENT_ON_TICKET,
      ASSIGN_TICKET,
      RESPOND_TO_TICKET,
      CHANGE_TICKET_STATUS,
    ],
    // [BUYERS_INTERESTS]: [],
    [PROFILE]: [],
  },
  [WAREHOUSE_MANAGER]: {
    [DASHBOARD]: [],
    [PURCHASES]: [],
    [CONSIGNMENTS]: [CREATE_CONSIGNMENT],
    [TRANSPORTS]: [ADD_TRANSPORT],
    [INVENTORY]: [
      DELETE_STOCK_ADJUSTMENT,
      CREATE_STOCK_ADJUSTMENT,
      CREATE_STOCK_TRANSFER,
      CREATE_WAREHOUSE,
      EDIT_WAREHOUSE,
      SUBMIT_STOCK_ADJUSTMENT,
      DOWNLOAD_CSV_INVENTORY,
    ],
    [PROFILE]: [],
  },
  [SALES_MANAGER]: {
    [DASHBOARD]: [],
    [HARVEST]: [],
    [PURCHASES]: [],
    [CONSIGNMENTS]: [CREATE_CONSIGNMENT, DOWNLOAD_CSV_CONSIGNMENTS],
    [INVENTORY]: [DOWNLOAD_CSV_INVENTORY],
    [SALES]: [
      CREATE_CUSTOMER,
      CREATE_SALES_INVOICE,
      ADD_TRANSPORT,
      SUBMIT_SALES_INVOICE,
      EDIT_CUSTOMER,
      DOWNLOAD_CSV_INVOICE,
    ],
    [BUYERS]: [DOWNLOAD_CSV_PO],
    [PROFILE]: [],
  },
  [ACCOUNTS_MANAGER]: {
    [DASHBOARD]: [],
    [PURCHASES]: [],
    [CONSIGNMENTS]: [],
    [SALES]: [],
    [ACCOUNTS]: [TRANSFER_FUNDS_INTERNALLY],
    [PROFILE]: [],
  },
  [ENGAGEMENT_MANAGER]: {
    [DASHBOARD]: [],
    [HARVEST]: [],
    [FARMERS]: [],
    [EXECUTIVES]: [
      PROMOTE_EXECUTIVES,
      REASSIGN_EXECUTIVES,
      DOWNLOAD_CSV_EXECUTIVES,
      ACTIVATE_EXECUTIVE,
      DEACTIVATE_EXECUTIVE,
      DELETE_LEAD_EXECUTIVES,
    ],
    [MANAGERS]: [],
    [BUYERS]: [],
    [PROFILE]: [],
    [ACCOUNTS]: [DOWNLOAD_BULK_PAYMENTS, UPLOAD_BULK_PAYMENT],
  },
  [PROCUREMENT_MANAGER]: {
    [DASHBOARD]: [],
    [COMMODITIES]: [],
    [HARVEST]: [DOWNLOAD_CSV_LEADS],
    [EXECUTIVES]: [],
    [FARMERS]: [DOWNLOAD_CSV, DELETE_HARVEST],
    [PURCHASES]: [],
    [CONSIGNMENTS]: [],
    [INVENTORY]: [],
    [PROFILE]: [],
  },
  [SENIOR_AGRONOMIST]: {
    [DASHBOARD]: [],
    [HARVEST]: [],
    [FARMERS]: [],
    [PROFILE]: [],
    [PACKAGE_OF_PRACTICE]: [],
    [COMPLAINT_TICKET]: [
      COMMENT_ON_TICKET,
      ASSIGN_TICKET,
      RESPOND_TO_TICKET,
      CHANGE_TICKET_STATUS,
    ],
    [TASKS]:[]
  },
  [JUNIOR_AGRONOMIST]: {
    [DASHBOARD]: [],
    [HARVEST]: [],
    [FARMERS]: [],
    [PROFILE]: [],
    [PACKAGE_OF_PRACTICE]: [],
    [COMPLAINT_TICKET]: [
      COMMENT_ON_TICKET,
      RESPOND_TO_TICKET,
      CHANGE_TICKET_STATUS,
    ],
    [TASKS]:[]
  },
});

export default ModulePermissions;
