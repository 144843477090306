import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  ADD_VARIANT: {
    URL: "orgs/add-variant/:id/",
    METHOD: "POST",
  },
  GET_VARIANTS: {
    URL: "orgs/variants/",
    METHOD: "GET",
  },
  CREATE_VARIANT: {
    URL: "orgs/variant/",
    METHOD: "POST",
  },
};

const variantsApi = {
  getVariants: async (orgid: string, commId: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_VARIANTS,
      options: {
        queryParams: {
          org_id: orgid,
          comm_id: commId,
        },
      },
    });
    return response;
  },

  addVariants: async (payload: object, id: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.ADD_VARIANT,
      options: {
        pathVars: {
          id,
        },
        data: {
          ...payload,
        },
      },
    });
    return response;
  },
  createVariant: async (variantData: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE_VARIANT,
      options: {
        data: variantData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
};

export default variantsApi;
