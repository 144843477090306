
import { NMessageProvider } from "naive-ui";
import DashboardLayout from "@/views/layout/Layout.vue";
import Modal from "./views/components/Modal.vue";
import Content from "@/views/components/Content.vue";

export default {
  components: {
    NMessageProvider,
    DashboardLayout,
    Modal,
    Content
  },
  setup() {
    return {};
  },
};
