import kyApi from "@/infra/services/api";
import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";
import { useModalStore } from "./modal.store";

import util from "@/utils/util";
import IFilters from "@/types/filter";
declare const window: any;

interface Details {
  attributes: any[];
  procurement: string | null;
}

export interface Commodity {
  display_name: string;
  group_name: string;
  gst_hsn_code: string;
  id: number;
  image: string;
  question_count: number;
  type: string;
  variant_count: number;
  org_name: string;
  crop_type: string | null;
  procurement: string | null;
  details: Details;
}

export interface CommodityGroup {
  id: number;
  name: string;
}

interface CommodityState {
  commodities: Commodity[];
  seasons: any[];
  configs: any[];
  commoditySeasons: any[];
  commodityGroups: CommodityGroup[];
  grades: any[];
  gradeAttributes: any;
  VariantGradeConfig: any;
  // variantGradeConfigPrice: any[];
  noGradePrices: any[];
  convertedGradesLedger: any[];
  convertedGradesTotalCount: number;
  batches: any[];
  batchDetails: any;
  batchesTotalCount: number;
  batchPrice: any;
  parsedConfigData: any;
  gradeConvertionFilters: IFilters;
  batchesFilters: IFilters;
  error: {
    getCommodities: string | null;
    getCommodityDetails: string | null;
    getCommodityGroups: string | null;
    createCommodity: string | null;
    getSeasons: string | null;
    getGrades: string | null;
    createCommodityGrade: string | null;
    createVariantGradeConfig: string | null;
    configGradePrice: string | null;
    getConfigs: string | null;
    selectProcurment: string | null;
    getCommoditySeasons: string | null;
    addNoGradePrice: string | null;
    getNoGradePrices: string | null;
    activeNoGradePrice: string | null;
    convertGrades: string | null;
    getConvertedGrades: string | null;
    getBatches: string | null;
    getBatchesDetails: string | null;
    getBatchPrice: string | null;
    submitGradeConversion: string | null;
    deleteGradeConversion: string | null;
    downloadPriceConfigExcel: string | null;
    uploadPriceConfigExcel: string | null;
    postCommoditiesPriceConfigs: string | null;
  };
  loading: {
    getCommodities: boolean;
    getCommodityDetails: boolean;
    getCommodityGroups: boolean;
    createCommodity: boolean;
    getSeasons: boolean;
    getGrades: boolean;
    createCommodityGrade: boolean;
    createVariantGradeConfig: boolean;
    configGradePrice: boolean;
    getConfigs: boolean;
    selectProcurment: boolean;
    getCommoditySeasons: boolean;
    addNoGradePrice: boolean;
    getNoGradePrices: boolean;
    activeNoGradePrice: boolean;
    convertGrades: boolean;
    getConvertedGrades: boolean;
    getBatches: boolean;
    getBatchesDetails: boolean;
    getBatchPrice: boolean;
    submitGradeConversion: boolean;
    deleteGradeConversion: boolean;
    downloadPriceConfigExcel: boolean;
    uploadPriceConfigExcel: boolean;
    postCommoditiesPriceConfigs: boolean;
  };
}

export const useCommodityStore = defineStore({
  id: "commodity",
  state: (): CommodityState => ({
    commodities: [],
    seasons: [],
    configs: [],
    commoditySeasons: [],
    commodityGroups: [],
    grades: [],
    gradeAttributes: {},
    VariantGradeConfig: {},
    // variantGradeConfigPrice: [],
    noGradePrices: [],
    convertedGradesLedger: [],
    convertedGradesTotalCount: 0,
    batches: [],
    batchDetails: { list: [], profit: null },
    batchesTotalCount: 0,
    parsedConfigData: {},
    batchPrice: { batch: null, price: null },
    gradeConvertionFilters: {
      commodity_id: {
        type: "Select",
        placeholder: "Commodity",
        initValue: null,
        value: null,
        options: [],
        itemText: "display_name",
        itemValue: "id",
      },
      variant: {
        type: "Select",
        placeholder: "Variant",
        initValue: null,
        value: null,
        options: [],
        itemText: "variant_name",
        itemValue: "variant_name",
      },
      grade: {
        type: "Select",
        placeholder: "Grades",
        initValue: null,
        value: null,
        options: [],
        itemText: "name",
        itemValue: "name",
      },
      created_at: {
        type: "Date Range",
        "end-placeholder": "",
        "start-placeholder": "Added On",
        separator: "",
        initValue: null,
        value: null,
      },
    },
    batchesFilters: {
      commodity_id: {
        type: "Select",
        placeholder: "Commodity",
        initValue: null,
        value: null,
        options: [],
        itemText: "display_name",
        itemValue: "id",
      },
      variant: {
        type: "Select",
        placeholder: "Variant",
        initValue: null,
        value: null,
        options: [],
        itemText: "variant_name",
        itemValue: "variant_name",
      },
      grade: {
        type: "Select",
        placeholder: "Grades",
        initValue: null,
        value: null,
        options: [],
        itemText: "name",
        itemValue: "name",
      },
      batch_no: {
        type: "Select",
        placeholder: "Batch",
        initValue: null,
        value: null,
        options: [],
      },
      farmer: {
        type: "Text",
        placeholder: "Farmer Name",
        initValue: null,
        value: null,
      },
      created_at: {
        type: "Date Range",
        "end-placeholder": "",
        "start-placeholder": "Added On",
        separator: "",
        initValue: null,
        value: null,
      },
    },
    error: {
      getCommodities: null,
      getCommodityDetails: null,
      getCommodityGroups: null,
      createCommodity: null,
      getSeasons: null,
      getGrades: null,
      createCommodityGrade: null,
      createVariantGradeConfig: null,
      configGradePrice: null,
      getConfigs: null,
      selectProcurment: null,
      getCommoditySeasons: null,
      addNoGradePrice: null,
      getNoGradePrices: null,
      activeNoGradePrice: null,
      convertGrades: null,
      getConvertedGrades: null,
      getBatches: null,
      getBatchesDetails: null,
      getBatchPrice: null,
      deleteGradeConversion: null,
      submitGradeConversion: null,
      downloadPriceConfigExcel: null,
      uploadPriceConfigExcel: null,
      postCommoditiesPriceConfigs: null,
    },
    loading: {
      getCommodityDetails: false,
      getCommodities: false,
      getCommodityGroups: false,
      createCommodity: false,
      getSeasons: false,
      getGrades: false,
      createCommodityGrade: false,
      createVariantGradeConfig: false,
      configGradePrice: false,
      getConfigs: false,
      selectProcurment: false,
      getCommoditySeasons: false,
      addNoGradePrice: false,
      getNoGradePrices: false,
      activeNoGradePrice: false,
      convertGrades: false,
      getConvertedGrades: false,
      getBatches: false,
      getBatchesDetails: false,
      getBatchPrice: false,
      deleteGradeConversion: false,
      submitGradeConversion: false,
      downloadPriceConfigExcel: false,
      uploadPriceConfigExcel: false,
      postCommoditiesPriceConfigs: false,
    },
  }),
  getters: {
    getCommodityById(state) {
      return (id: number) => state.commodities.find((comm) => comm.id === id);
    },
    getcommodityIdByName(state) {
      return (commName: string) =>
        state.commodities.find((comm) => comm.display_name === commName);
    },
    getConfigById(state) {
      return (configId: number) =>
        state.configs.find((config) => config.id === configId);
    },
  },
  actions: {
    initGradeConvertionFilters(options: { key: string; value: any }[]) {
      options.forEach((option) => {
        this.gradeConvertionFilters[option.key].options = option.value;
      });
    },
    clearGradeConvertionFilters() {
      Object.keys(this.gradeConvertionFilters).forEach((key) => {
        this.gradeConvertionFilters[key].value =
          this.gradeConvertionFilters[key].initValue;
      });
    },
    initBatchesFilters(options: { key: string; value: any }[]) {
      options.forEach((option) => {
        this.batchesFilters[option.key].options = option.value;
      });
    },
    clearBatchesFilters() {
      Object.keys(this.batchesFilters).forEach((key) => {
        this.batchesFilters[key].value = this.batchesFilters[key].initValue;
      });
    },
    clearBatchPrice() {
      this.batchPrice = { batch: null, price: null };
    },
    async getCommodities(paramsPayload?: any) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getCommodities = "User not available";
        return;
      }
      const params = {
        ...paramsPayload,
        org_id: paramsPayload?.org_id || authStore.user.orgId || "",
      };
      if (util.checkIfAdminSubDomain()) {
        params.is_super_admin = 1;
      }
      this.loading.getCommodities = true;
      const response = await kyApi.commodity.getCommodities(params);

      if (response.error) {
        this.error.getCommodities = response.message;
        window.$message.error(response.message);
      } else {
        this.commodities = response.data.sort((a: any, b: any) =>
          a.display_name.localeCompare(b.display_name)
        );
      }
      this.loading.getCommodities = false;
    },

    async getCommodityDetails(commId: number) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getCommodityDetails = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getCommodityDetails = true;
      if (!this.commodities.length) {
        console.log("commodity no length");
        this.getCommodities();
      }
      const response = await kyApi.commodity.getCommodityDetails(
        authStore.user.orgId,
        commId
      );

      if (response.error) {
        this.error.getCommodityDetails = response.message;
        window.$message.error(response.message);
      } else {
        // this.commodities = response.data;
        let currentCommodity = this.commodities.find((i) => i.id === commId);
        if (currentCommodity) {
          currentCommodity = { ...currentCommodity, ...response.data };
          const currentCommodityIndex = this.commodities.findIndex(
            (i) => i.id === commId
          );
          this.commodities[currentCommodityIndex] =
            currentCommodity as Commodity;
        } else {
          this.commodities.push(response.data);
        }
      }
      this.loading.getCommodityDetails = false;
    },

    async getCommodityGroups() {
      this.loading.getCommodityGroups = true;
      const response = await kyApi.commodity.getCommodityGroups();

      if (response.error) {
        this.error.getCommodityGroups = response.message;
        window.$message.error(response.message);
      } else {
        this.commodityGroups = response.data;
      }
      this.loading.getCommodityGroups = false;
    },

    async createCommodity(formData: any) {
      const authStore = useAuthStore();
      const modal = useModalStore();

      if (!authStore.user) {
        this.error.getCommodities = "User not available";
        return;
      }
      this.loading.createCommodity = true;
      const response = await kyApi.commodity.createCommodity(formData);
      if (response.error) {
        this.error.createCommodity = response.message;
        window.$message.error(response.message);
      } else {
        modal.close();
        this.getCommodities();
        if (response.message === "Success") {
          window.$message.success(response.message);
        } else {
          window.$message.error("Something went wrong!");
        }
      }
      this.loading.createCommodity = false;
    },

    async getSeasons() {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getSeasons = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getSeasons = true;
      const response = await kyApi.commodity.getAllSeasons();

      if (response.error) {
        this.error.getSeasons = response.message;
        window.$message.error(response.message);
      } else {
        this.seasons = response.data;
      }
      this.loading.getSeasons = false;
    },

    async getGrades(commId: number, payload?: any) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getGrades = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getGrades = true;
      const response = await kyApi.commodity.getGrades(commId, payload);

      if (response.error) {
        this.error.getGrades = response.message;
        window.$message.error(response.message);
      } else {
        this.grades = response.data.grades;
        this.gradeAttributes = response.data.attribute;

        // const column = [
        //   ...new Set(
        //     response.data.grades.map((a) => a.attribute_option_1_name)
        //   ),
        // ];

        // const headers = () => {
        //   const header: any = [{ title: "", key: "Grade" }];
        //   for (let i = 0; i < column.length; i++) {
        //     header.push({
        //       title: column[i],
        //       key: column[i],
        //     });
        //   }
        //   console.log("headers", header);
        //   return header;
        // };

        // const data = () => {
        //   const rowKeys = {};
        //   response.data.grades.forEach((a: any) => {
        //     if (!rowKeys[a.attribute_option_2_name]) {
        //       rowKeys[a.attribute_option_2_name] = [];
        //     }
        //     rowKeys[a.attribute_option_2_name].push(a);
        //   });

        //   const data: any = [];
        //   for (const x in rowKeys) {
        //     const ob = {
        //       Grade: { name: x },
        //     };
        //     column.forEach((a: any) => (ob[a] = ""));
        //     for (let i = 0; i < rowKeys[x].length; i++) {
        //       const d = rowKeys[x][i];
        //       ob[d.attribute_option_1_name] = d;
        //     }
        //     data.push(ob);
        //   }
        //   console.log("DATA", data);
        //   return data;
        // };
      }
      this.loading.getGrades = false;
    },
    async createCommodityGrade(commId: number, payload: object) {
      const authStore = useAuthStore();
      const modal = useModalStore();

      if (!authStore.user) {
        this.error.createCommodityGrade = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.createCommodityGrade = true;
      const response = await kyApi.commodity.createCommodityGrade(
        commId,
        payload
      );

      if (response.error) {
        this.error.createCommodityGrade = response.message;
        window.$message.error(response.message);
      } else {
        this.grades = response.data;
        if (response.message === "Success") {
          window.$message.success(response.message);
          modal.close();
          this.getGrades(commId);
        }
      }
      this.loading.createCommodityGrade = false;
    },

    async createVariantGradeConfig(payload: any, makeActive) {
      const authStore = useAuthStore();
      const modal = useModalStore();

      if (!authStore.user) {
        this.error.createVariantGradeConfig = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.createVariantGradeConfig = true;
      const response = await kyApi.commodity.createVariantGradeConfig(payload);

      if (response.error) {
        this.error.createVariantGradeConfig = response.message;
        window.$message.error(response.message);
      } else {
        this.VariantGradeConfig = response.data;
        if (makeActive) {
          window.$message.success(response.message);
          modal.close();
          this.getConfigs(payload.variant);
        }
      }
      this.loading.createVariantGradeConfig = false;
    },
    async addCommodityconfigGradePrice(payload: any) {
      const authStore = useAuthStore();
      const modal = useModalStore();

      if (!authStore.user) {
        this.error.configGradePrice = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.configGradePrice = true;
      const response = await kyApi.commodity.addCommodityConfigGradePrice(
        payload
      );

      if (response.error) {
        this.error.configGradePrice = response.message;
        window.$message.error(response.message);
      } else {
        // this.variantGradeConfigPrice = response.data;
        if (response.message === "Success") {
          window.$message.success(response.message);
          modal.close();
          this.getConfigs(payload.variantId);
        }
      }
      this.loading.configGradePrice = false;
    },
    async addNoGradePrice(payload: any) {
      const authStore = useAuthStore();
      const modal = useModalStore();
      if (!authStore.user) {
        this.error.addNoGradePrice = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.addNoGradePrice = true;
      const response = await kyApi.commodity.addNoGradePrice(
        authStore.user.orgId,
        payload
      );

      if (response.error) {
        this.error.addNoGradePrice = response.message;
        window.$message.error(response.message);
      } else {
        if (response.message === "Success") {
          window.$message.success(response.message);
          modal.close();
          this.getNoGradePrices(payload.variant_id);
        }
      }
      this.loading.addNoGradePrice = false;
    },
    async getNoGradePrices(variantId: number) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getNoGradePrices = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getNoGradePrices = true;
      const response = await kyApi.commodity.getNoGradePrice(
        authStore.user.orgId,
        variantId
      );

      if (response.error) {
        this.error.getNoGradePrices = response.message;
        window.$message.error(response.message);
      } else {
        this.noGradePrices = response.data;
      }
      this.loading.getNoGradePrices = false;
    },
    async activeNoGradePrice(variantId: number, payload: any) {
      const modal = useModalStore();
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.activeNoGradePrice = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.activeNoGradePrice = true;
      const response = await kyApi.commodity.activeNoGradePrice(
        authStore.user.orgId,
        variantId,
        payload
      );

      if (response.error) {
        this.error.activeNoGradePrice = response.message;
        window.$message.error(response.message);
      } else {
        if (response.message === "Success") {
          window.$message.success(response.message);
          modal.close();
          this.getNoGradePrices(variantId);
        }
      }
      this.loading.activeNoGradePrice = false;
    },
    async getConfigs(variantId: number) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getConfigs = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getConfigs = true;
      const response = await kyApi.commodity.getConfigs(variantId);

      if (response.error) {
        this.error.getConfigs = response.message;
        window.$message.error(response.message);
      } else {
        this.configs = response.data;
      }
      this.loading.getConfigs = false;
    },
    async selectProcurment(payload: any) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.selectProcurment = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.selectProcurment = true;
      const response = await kyApi.commodity.selectProcurment(
        authStore.user.orgId,
        payload
      );

      if (response.error) {
        this.error.selectProcurment = response.message;
        window.$message.error(response.message);
      } else {
        // this.configs = response.data;
        window.$message.success(response.message);
        this.getCommodityDetails(payload.commodity);
      }
      this.loading.selectProcurment = false;
    },
    async getCommoditySeasons(commId: number) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getCommoditySeasons = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getCommoditySeasons = true;
      const response = await kyApi.commodity.getCommoditySeasons(commId);

      if (response.error) {
        this.error.getCommoditySeasons = response.message;
        window.$message.error(response.message);
      } else {
        this.commoditySeasons = response.data;
      }
      this.loading.getCommoditySeasons = false;
    },
    async convertGrades(payload: any) {
      const authStore = useAuthStore();
      const modal = useModalStore();

      if (!authStore.user) {
        this.error.convertGrades = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.convertGrades = true;
      const response = await kyApi.commodity.convertGrades(
        { org_id: authStore.user.orgId },
        payload
      );

      if (response.error) {
        this.error.convertGrades = response.message;
        window.$message.error(response.message);
      } else {
        window.$message.success(response.message);
        modal.close();
        this.getConvertGrades({});
      }
      this.loading.convertGrades = false;
    },
    async getConvertGrades(payload: any) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getConvertedGrades = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getConvertedGrades = true;
      const response = await kyApi.commodity.getConvertGrades({
        ...payload,
        org_id: authStore.user.orgId,
      });

      if (response.error) {
        this.error.getConvertedGrades = response.message;
        window.$message.error(response.message);
      } else {
        this.convertedGradesLedger = response.data.list;
        this.convertedGradesTotalCount = response.data.total;
      }
      this.loading.getConvertedGrades = false;
    },
    async getBatches(payload: any, filterPayload: any) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getBatches = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getBatches = true;
      const response = await kyApi.commodity.getBatches(
        {
          ...payload,
          org_id: authStore.user.orgId,
        },
        filterPayload
      );

      if (response.error) {
        this.error.getBatches = response.message;
        window.$message.error(response.message);
      } else {
        this.batches = response.data.list;
        this.batchesTotalCount = response.data.total;
      }
      this.loading.getBatches = false;
    },
    async getBatchesDetails(payload: any, batch_id: string | number) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getBatchesDetails = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getBatchesDetails = true;
      const response = await kyApi.commodity.getBatcheDetails(
        {
          ...payload,
          org_id: authStore.user.orgId,
        },
        batch_id
      );

      if (response.error) {
        this.error.getBatchesDetails = response.message;
        window.$message.error(response.message);
      } else {
        this.batchDetails = response.data;
      }
      this.loading.getBatchesDetails = false;
    },

    async getBatchPrice(payload: any) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getBatchPrice = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.getBatchPrice = true;
      const response = await kyApi.commodity.getBatchPrice({
        ...payload,
        org_id: authStore.user.orgId,
      });

      if (response.error) {
        this.error.getBatchPrice = response.message;
        window.$message.error(response.message);
      } else {
        this.batchPrice = response.data;
      }
      this.loading.getBatchPrice = false;
    },
    async submitGradeConversion(id: number | string) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.submitGradeConversion = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.submitGradeConversion = true;
      const response = await kyApi.commodity.submitGradeConversion(
        {
          org_id: authStore.user.orgId,
        },
        id
      );

      if (response.error) {
        this.error.submitGradeConversion = response.message;
        window.$message.error(response.message);
      } else {
        // this.getConvertGrades({});
      }
      this.loading.submitGradeConversion = false;
    },
    async deleteGradeConversion(id: number | string) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.deleteGradeConversion = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.deleteGradeConversion = true;
      const response = await kyApi.commodity.deleteGradeConversion(
        {
          org_id: authStore.user.orgId,
        },
        id
      );

      if (response.error) {
        this.error.deleteGradeConversion = response.message;
        window.$message.error(response.message);
      } else {
        // this.getConvertGrades({});
      }
      this.loading.deleteGradeConversion = false;
    },

    async downloadPriceConfigExcel() {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.downloadPriceConfigExcel = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.downloadPriceConfigExcel = true;
      const response = await kyApi.commodity.downloadPriceConfigExcel({
        org_id: authStore.user.orgId,
      });

      if (response.error) {
        this.error.downloadPriceConfigExcel = response.message;
        window.$message.error(response.message);
      } else {
        window.open(response.data, "_blank");
        //  this.priceConfigExcel = response.data;
      }
      this.loading.downloadPriceConfigExcel = false;
    },
    async uploadPriceConfigExcel(payload: any) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.uploadPriceConfigExcel = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.uploadPriceConfigExcel = true;
      const response = await kyApi.commodity.parsedPriceConfigs(payload, {
        org_id: authStore.user.orgId,
      });

      if (response.error) {
        this.error.uploadPriceConfigExcel = response.message;
        window.$message.error(response.message);
      } else {
        this.parsedConfigData = response.data;
      }
      this.loading.uploadPriceConfigExcel = false;
    },
    async postCommoditiesPriceConfigs(payload: any) {
      const authStore = useAuthStore();
      const modalStore = useModalStore();

      if (!authStore.user) {
        this.error.postCommoditiesPriceConfigs = "User not available";
        console.log("user not avail");
        return;
      }
      this.loading.postCommoditiesPriceConfigs = true;
      const response = await kyApi.commodity.postCommoditiesPriceConfigs(
        payload,
        {
          org_id: authStore.user.orgId,
        }
      );

      if (response.error) {
        this.error.postCommoditiesPriceConfigs = response.message;
        window.$message.error(response.message);
      } else {
        window.$message.success(response.message);
        modalStore.close();
      }
      this.loading.postCommoditiesPriceConfigs = false;
    },
  },
});

export default useCommodityStore;
