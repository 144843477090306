
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth.store";
import Sidebar from "@/views/layout/MenuList.vue";
import { useRoute } from "vue-router";
import {
  NLayout,
  NLayoutContent,
  NSpace,
  NLayoutHeader,
  NAvatar,
  NH4,
} from "naive-ui";
import Login from "../pages/auth/Login.vue";
import Button from "@/views/components/Button/Button.vue";
import DeleteAccountForm from "@/views/pages/AccountDelete/DeleteAccountForm.vue"

import util from "@/utils/util";

export default {
  name: "DashboardLayout",
  components: {
    Sidebar,
    NLayout,
    NLayoutContent,
    NSpace,
    NLayoutHeader,
    Login,
    NAvatar,
    Button,
    NH4,
    DeleteAccountForm
  },
  setup() {
    const routes = useRoute();
    const authStore = useAuthStore();
    const { isAuthenticated, user } = storeToRefs(authStore);

    console.log(routes)

    return {
      isAuthenticated,
      logout: authStore.logout,
      user,
      routes,
      util
    };
  },
};
