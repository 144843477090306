import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL: {
    URL: "orgs/warehouses/",
    METHOD: "GET",
  },
  CREATE: {
    URL: "orgs/warehouse/",
    METHOD: "POST",
  },
  GET: {
    URL: "orgs/warehouse/:id/",
    METHOD: "GET",
  },
  GET_AVAIL_ITEMS_WAREHOUSE: {
    URL: "orgs/available/items",
    METHOD: "POST",
  },
};

const warehouseApi = {
  getWarehouses: async (pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL,
      options: {
        queryParams: {
          ...pagination,
        },
      },
    });
    return response;
  },
  getAvailItemsInWarehouse: async (payload: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_AVAIL_ITEMS_WAREHOUSE,
      options: {
        data: {
          ...payload,
        },
      },
    });
    return response;
  },
  getWarehouseDetails: async (warehouseId: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET,
      options: {
        pathVars: {
          id: warehouseId,
        },
      },
    });
    return response;
  },
  createWarehouse: async (warehouseData: object, updateFlag?: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE,
      options: {
        data: {
          ...warehouseData,
        },
        queryParams: {
          updated: updateFlag as string,
        },
      },
    });
    return response;
  },
};

export default warehouseApi;
