import kyApi from "@/infra/services/api";
import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";
declare const window: any;

interface RequestStore {
  requests: any[];
  totalRequests: number;
  requestConfirm: number | null;
  error: {
    getRequests: string | null;
    createRequest: string | null;
    getOTP: string | null;
  };
  loading: {
    getRequests: boolean;
    createRequest: boolean;
    getOTP: boolean;
  };
}

export const useRequestStore = defineStore({
  id: "requestsStore",
  state: (): RequestStore => ({
    requests: [],
    totalRequests: 0,
    requestConfirm: null,
    error: {
      getRequests: null,
      createRequest: null,
      getOTP: null,
    },
    loading: {
      getRequests: false,
      createRequest: false,
      getOTP: false,
    },
  }),
  actions: {
    async getRequests(pagination: object) {
      const authStore = useAuthStore();
      if (!authStore.user) {
        this.error.getRequests = "User not available";
        return;
      }
      this.loading.getRequests = true;
      const response = await kyApi.requests.getDeleteRequests({
        ...pagination,
        org_id: authStore.user?.orgId,
      });

      if (response.error) {
        this.error.getRequests = response.message;
        window.$message.error(response.message);
      } else {
        this.requests = response.data.list;
        this.totalRequests = response.data.total || 0;
      }
      this.loading.getRequests = false;
    },
    async createRequest(formPayload: object) {
      this.loading.createRequest = true;
      const response = await kyApi.requests.createDeleteRequest(formPayload);

      if (response.error) {
        this.error.createRequest = response.message;
        window.$message.error(response.message);
      } else {
        if (response.message === "Success") {
          window.$message.success(response.message);
          this.requestConfirm = response.data;
        } else {
          window.$message.error("Something went wrong!");
        }
      }
      this.loading.createRequest = false;
    },
    async getOTP(formPayload: object) {
      this.loading.getOTP = true;
      const response = await kyApi.requests.getOTP(formPayload);

      if (response.error) {
        this.error.getOTP = response.message;
        window.$message.error(response.message);
      } else {
        if (response.message === "Success") {
          window.$message.success(response.message);
        } else {
          window.$message.error("Something went wrong!");
        }
      }
      this.loading.getOTP = false;
    },
  },
});

export default useRequestStore;
