import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!

  return (_openBlock(), _createBlock(_component_n_button, { attr: _ctx.$attrs }, _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 2
  }, [
    (_ctx.$props?.icon)
      ? {
          name: "icon",
          fn: _withCtx(() => [
            _createVNode(_component_n_icon, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "icon")
              ]),
              _: 3
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["attr"]))
}