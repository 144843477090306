import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_CHEMICAL: {
    URL: "orgs/chemical/",
    METHOD: "GET",
  },
  ADD_CHEMICAL: {
    URL: "orgs/chemical/",
    METHOD: "POST",
  },
};

const ferilizerApi = {
  getAllFertilizer: async (pagination: object) => {
    const response = await kyApiService({
      resource: API.GET_CHEMICAL,
      options: {
        queryParams: {
          ...pagination,
        },
      },
    });

    return response;
  },
  createFertilizer: async (payload: object) => {
    const response = await kyApiService({
      resource: API.ADD_CHEMICAL,
      options: {
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  getAllPesticides: async (pagination: object) => {
    const response = await kyApiService({
      resource: API.GET_CHEMICAL,
      options: {
        queryParams: {
          ...pagination,
        },
      },
    });

    return response;
  },
  createPesticide: async (payload: object) => {
    const response = await kyApiService({
      resource: API.ADD_CHEMICAL,
      options: {
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default ferilizerApi;
