// import { EventBus } from "./constants";
import IFilters from "@/types/filter";
import moment from "moment/moment";
import CONSTANTS from "./constants";

export default {
  subscribeEvent: function (event: any, callback: any) {
    // EventBus.$on(event, callback);
  },
  emitEvent: function (event: any, data: any) {
    // EventBus.$emit(event, data);
  },
  updateSnackbar: function (data: any) {
    // store.dispatch(UPDATE_SNACKBAR, data);
  },
  getValuesForFilters(filters: IFilters) {
    const values: {
      [key: string]: any;
    } = {};
    Object.keys(filters).forEach((key: string) => {
      const filter = filters[key];
      values[key] =
        filter.type === "Numeric" ? parseInt(filter.value) : filter.value;
    });
    return values;
  },
  orderByLogic(filters: {
    sort_order: { value: any };
    order_by: { value: any };
  }) {
    return (filters.sort_order?.value || "") + (filters.order_by?.value || "");
  },
  openLinkInNewTab(link: string | URL | undefined, new_tab = true) {
    if (new_tab) window.open(link, "_blank");
    else window.open(link, "_self");
  },
  validateUrl(value: string) {
    if (!value) return false;
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  },
  getMonthAgoStr() {
    return moment().subtract(1, "month").format("YYYY-MM-DD");
  },
  getTodayStr() {
    return moment().format("YYYY-MM-DD");
  },
  beautifyDateTime: function (date: moment.MomentInput, time = true) {
    if (!date) return null;
    if (time)
      return moment(date).isValid()
        ? moment(date).format("Do MMM, YYYY h:mm A")
        : "N/A";
    else
      return moment(date).isValid()
        ? moment(date).format("Do MMM, YYYY")
        : "N/A";
  },
  getSubDomain: function () {
    return window.location.hostname.split(".")[0];
  },
  checkIfAdminSubDomain: function() {
    const subDomain = this.getSubDomain();
   return subDomain.includes("admin")
  },
  pan_number: (v: any) => 
  /^[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/.test(v || "AAAPL1234C")
 || "Not a valid Indian PAN Number",
};
