import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Content = _resolveComponent("Content")!
  const _component_DashboardLayout = _resolveComponent("DashboardLayout")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!

  return (_openBlock(), _createBlock(_component_n_message_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_Modal),
      _createVNode(_component_Content),
      _createVNode(_component_DashboardLayout)
    ]),
    _: 1
  }))
}