import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { href: "/" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteAccountForm = _resolveComponent("DeleteAccountForm")!
  const _component_Login = _resolveComponent("Login")!
  const _component_n_avatar = _resolveComponent("n-avatar")!
  const _component_n_h4 = _resolveComponent("n-h4")!
  const _component_Button = _resolveComponent("Button")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_layout_header = _resolveComponent("n-layout-header")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_n_layout_content = _resolveComponent("n-layout-content")!
  const _component_n_layout = _resolveComponent("n-layout")!

  return (_openBlock(), _createBlock(_component_n_space, {
    vertical: "",
    size: "large"
  }, {
    default: _withCtx(() => [
      ($setup.util.checkIfAdminSubDomain() && $setup.routes.path === '/account-delete')
        ? (_openBlock(), _createBlock(_component_DeleteAccountForm, { key: 0 }))
        : _createCommentVNode("", true),
      (!$setup.isAuthenticated && $setup.routes.path !== '/account-delete')
        ? (_openBlock(), _createBlock(_component_Login, { key: 1 }))
        : _createCommentVNode("", true),
      ($setup.isAuthenticated)
        ? (_openBlock(), _createBlock(_component_n_layout, {
            key: 2,
            class: "main-layout"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_layout_header, { bordered: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_space, {
                    justify: "space-between",
                    align: "center"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", _hoisted_1, [
                        _createVNode(_component_n_avatar, {
                          class: "ml-3 org-logo",
                          size: "large",
                          src: "/media/logos/logo.webp"
                        })
                      ]),
                      _createVNode(_component_n_space, { align: "center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_n_h4, { class: "user-name mr-3" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Hi, " + _toDisplayString($setup.user?.name), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Button, {
                            class: "mr-3",
                            type: "primary",
                            ghost: "",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.logout()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Logout ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_n_layout, { "has-sider": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Sidebar),
                  _createVNode(_component_n_layout_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, { fluid: "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_view)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}