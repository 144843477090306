import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_TICKETS: {
    URL: "orgs/tickets/",
    METHOD: "POST",
  },
  GET_TICKET_DETAILS: {
    URL: "orgs/ticket/:id/",
    METHOD: "GET",
  },
  RESPOND_TICKET: {
    URL: "orgs/ticket/:id/",
    METHOD: "PUT",
  },
  ADD_COMMENT: {
    URL: "orgs/add/comment/",
    METHOD: "POST",
  },
  GET_JR_AGRONOMISTS: {
    URL: "orgs/jr-agronomists/",
    METHOD: "GET",
  },
  GET_TASK_DETAILS: {
    URL: "orgs/task/:id/",
    METHOD: "GET",
  },
  CREATE_TASK: {
    URL: "orgs/task/",
    METHOD: "POST",
  },
  RE_ASSIGN_TICKETS: {
    URL: "orgs/reassign/ticket/",
    METHOD: "POST",
  },
};

const ticketApi = {
  getAllTickets: async (pagination: object, filterPayload: any) => {
    const response = await kyApiService({
      resource: API.GET_TICKETS,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...filterPayload,
        },
      },
    });

    return response;
  },
  respondToTicket: async (payload: object, ticketID: number) => {
    const response = await kyApiService({
      resource: API.RESPOND_TICKET,
      options: {
        data: {
          ...payload,
        },
        pathVars: {
          id: ticketID,
        },
      },
    });

    return response;
  },
  getTicketDetails: async (ticketID: number, orgId: string) => {
    const response = await kyApiService({
      resource: API.GET_TICKET_DETAILS,
      options: {
        pathVars: {
          id: ticketID,
        },
        queryParams: {
          org_id: orgId,
        },
      },
    });

    return response;
  },

  addCommentAsImage: async (payload: any) => {
    const response = await kyApiService({
      resource: API.ADD_COMMENT,
      options: {
        data: payload,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },

  addCommentAsText: async (payload: any, paramsData: any) => {
    const response = await kyApiService({
      resource: API.ADD_COMMENT,
      options: {
        data: {
          ...payload,
          ...paramsData,
        },
      },
    });

    return response;
  },
  jrAgronomists: async (paramsData: any) => {
    const response = await kyApiService({
      resource: API.GET_JR_AGRONOMISTS,
      options: {
        queryParams: {
          ...paramsData,
        },
      },
    });

    return response;
  },
  getTasks: async (pagination: object, filterPayload: any) => {
    const response = await kyApiService({
      resource: API.GET_TICKETS,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...filterPayload,
        },
      },
    });

    return response;
  },
  getTaskDetails: async (payload: any, taskID: number) => {
    const response = await kyApiService({
      resource: API.GET_TASK_DETAILS,
      options: {
        queryParams: {
          ...payload,
        },
        pathVars: {
          id: taskID,
        },
      },
    });

    return response;
  },
  createTask: async (payload: any, paramsData: any) => {
    const response = await kyApiService({
      resource: API.CREATE_TASK,
      options: {
        queryParams: {
          ...paramsData,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  reassignTickets: async (payload: any, paramsData: any) => {
    const response = await kyApiService({
      resource: API.RE_ASSIGN_TICKETS,
      options: {
        queryParams: {
          ...paramsData,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default ticketApi;
