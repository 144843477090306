import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_TRUCK_DRIVERS: {
    URL: "/orgs/transport-details/",
    METHOD: "GET",
  },
  ADD_TRUCK_DRIVER: {
    URL: "/orgs/transport-details/",
    METHOD: "POST",
  },
};

const transportApi = {
  getTruckDrivers: async (orgId: string, payload: any) => {
    const response = await kyApiService({
      resource: API.GET_TRUCK_DRIVERS,
      options: {
        queryParams: {
          org_id: orgId,
          ...payload,
        },
      },
    });

    return response;
  },
  addTruckDriver: async (orgId: string, payload: object) => {
    const response = await kyApiService({
      resource: API.ADD_TRUCK_DRIVER,
      options: {
        queryParams: {
          org_id: orgId,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default transportApi;
