
import { ref, watch } from "vue";
import ShadowCard from "@/views/components/ShadowCard.vue";
import TextField from "@/views/components/form/Input.vue";
import Button from "@/views/components/Button/Button.vue";
import FormItem from "@/views/components/form/FormItem.vue";

import useRequestStore from "@/stores/deleteAccountRequest.store";
import { storeToRefs } from "pinia";

import {
  NForm,
  FormRules,
  FormValidationError,
  FormInst,
  useMessage,
  NGi,
  NGrid,
  FormItemRule,
} from "naive-ui";

export default {
  name: "DeleteAccountForm",
  components: { ShadowCard, NForm, NGi, NGrid, TextField, Button, FormItem },
  setup() {
    const formRef = ref<FormInst | null>(null);
    const message = useMessage();
    const requestStore = useRequestStore();

    const { requestConfirm } = storeToRefs(requestStore);

    const modelRef: any = ref({
      phone_number: null,
      otp: null,
      reason: null,
    });

    const rules: FormRules = {
      phone_number: [
        {
          required: true,
          validator(rule: FormItemRule, value: string) {
            if (!value) {
              return new Error("Please provide phone number");
            } else if (!/^\d{10}$/.test(value)) {
              return new Error("Invalid phone number");
            }
            return true;
          },
          trigger: ["blur", "input"],
        },
      ],
      otp: [
        {
          required: true,
          validator(rule: FormItemRule, value: string) {
            if (!value) {
              return new Error("Please fill OTP");
            }
            return true;
          },
          trigger: ["blur", "input"],
        },
      ],
      reason: [
        {
          required: true,
          validator(rule: FormItemRule, value: string) {
            if (!value) {
              return new Error("Please fill reason");
            }
            return true;
          },
          trigger: ["blur", "input"],
        },
      ],
    };

    const handleGetOTP = () => {
      if (modelRef.value.phone_number) {
        requestStore.getOTP({
          phone_number: `+91${modelRef.value.phone_number}`,
        });
      }
    };

    watch(requestConfirm, (newVal, preVal) => {
      if (newVal && newVal !== preVal) {
        modelRef.value.phone_number = null;
        modelRef.value.otp = null;
        modelRef.value.reason = null;
      }
    });

    const submitForm = () => {
      formRef.value?.validate(
        (errors: Array<FormValidationError> | undefined) => {
          if (!errors) {
            if (modelRef.value.otp && modelRef.value.phone_number) {
              const data: any = {
                otp: modelRef.value.otp,
                phone_number: `+91${modelRef.value.phone_number}`,
                reason: modelRef.value.reason,
              };
              requestStore.createRequest(data);
            }
          } else {
            message.error("Invalid Data");
          }
        }
      );
    };

    return {
      formRef,
      model: modelRef,
      rules,
      handleGetOTP,
      submitForm,
    };
  },
};
