import authApi from "./auth.api";
import dashboardApi from "./dashboard.api";
import organizationApi from "./organization.api";
import commodityApi from "./commodity.api";
import leadsApi from "./leads.api";
import variantsApi from "./variants.api";
import questionsApi from "./questions.api";
import profileApi from "./profile.api";
import regionApi from "./region.api";
import purchaseApi from "./purchase.api";
import paymentApi from "./payment.api";
import deductionApi from "./deduction.api";
import reportApi from "./report.api";
import buyerApi from "./buyer.api";
import userApi from "./user.api";
import salesApi from "./sales.api";
import warehouseApi from "./warehouse.api";
import stockEntryApi from "./stockentry.api";
import inventoryApi from "./inventory.api";
import stockAdjustmentApi from "./stockAdjustment.api";
// import managerApi from "./manager.api";
import accountApi from "./account.api";
import cropApi from "./crop.api";
import attributeApi from "./attribute.api";
import mediaApi from "./media.api";
import bankApi from "./bank.api";
import consignmentApi from "./consignment.api";
import transportApi from "./transport.api";
import incentiveApi from "./incentive.api";
import processedApi from "./processed.api";
import insuranceApi from "./insurance.api";
import fertilizerApi from "./fertilizer.api";
import POPApi from "./pop.api";
import ticketApi from "./ticket.api";
import timelineApi from "./timeline.api";
import requestApi from "./deleteRequest.api";

const kyApi = {
  auth: authApi,
  organization: organizationApi,
  dashboard: dashboardApi,
  commodity: commodityApi,
  leads: leadsApi,
  variants: variantsApi,
  questions: questionsApi,
  profile: profileApi,
  region: regionApi,
  purchase: purchaseApi,
  payment: paymentApi,
  deduction: deductionApi,
  report: reportApi,
  buyer: buyerApi,
  user: userApi,
  sales: salesApi,
  warehouse: warehouseApi,
  stockEntry: stockEntryApi,
  inventory: inventoryApi,
  stockAdjustment: stockAdjustmentApi,
  // manager: managerApi,
  account: accountApi,
  crops: cropApi,
  attributes: attributeApi,
  media: mediaApi,
  bank: bankApi,
  consignment: consignmentApi,
  transport: transportApi,
  incentive: incentiveApi,
  processed: processedApi,
  insurance: insuranceApi,
  fertilizer: fertilizerApi,
  packageOfPractice: POPApi,
  complaintTicket: ticketApi,
  farmerTimeline: timelineApi,
  requests: requestApi,
};

export default kyApi;
