import kyApiService from "@/infra/plugins/axios";
import { ApiResources } from "@/types/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ORGANIZATION_DETAILS: {
    URL: "orgs/organization/:id/",
    METHOD: "GET",
  },
  GET_ALL_ORGANIZATIONS: {
    URL: "orgs/organizations/",
    METHOD: "GET",
  },
  CREATE_ORGANIZATION: {
    URL: "orgs/organization/",
    METHOD: "POST",
  },
  ADD_CDMS: {
    URL: "orgs/add-cdms/:id/",
    METHOD: "POST",
  },
  GET_ADDITIONAL_MODULE: {
    URL: "orgs/module/:id",
    METHOD: "GET",
  },
  UPDATE_ADDITIONAL_MODULE: {
    URL: "orgs/module/:id",
    METHOD: "PUT",
  },
};

const organizationApi = {
  getOrganizationDetails: async (orgId: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ORGANIZATION_DETAILS,
      options: {
        pathVars: {
          id: orgId,
        },
      },
    });
    return response;
  },
  getAllOrganizations: async () => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_ORGANIZATIONS,
    });
    return response;
  },
  createOrganization: async (formData: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE_ORGANIZATION,
      options: {
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  },
  getAdditionalModules: async (id: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ADDITIONAL_MODULE,
      options: {
        pathVars: { id },
      },
    });
    return response;
  },
  updateAdditionalModules: async (id: number, data: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.UPDATE_ADDITIONAL_MODULE,
      options: {
        pathVars: { id },
        data: {
          ...data,
        },
      },
    });
    return response;
  },
};

export default organizationApi;

// export const getOrgVariantApi: GetOrganizationVariant = {
//   getOrganizationVariant: async () => {
//     const { data, error } = await kyApiService({
//       resource: API_ENDPOINTS.GET_VARIANTS,
//       options: {
//         auth: true,
//       },
//     });

//     if (error) {
//       throw new Error("Error in fetching organization variant");
//     }

//     return data;
//   },
// };
