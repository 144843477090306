import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_COMMODITY: ApiResources = {
  GET: {
    URL: "orgs/commodity/:id/",
    METHOD: "GET",
  },
  GET_ALL: {
    URL: "orgs/commodities/",
    METHOD: "GET",
  },
  CREATE: {
    URL: "orgs/commodity/",
    METHOD: "POST",
  },
  GET_COMMODITY_GROUPS: {
    URL: "orgs/commodity-groups/",
    METHOD: "GET",
  },
  GET_SEASONS: {
    URL: "orgs/commodity-seasons/",
    METHOD: "GET",
  },
  GET_GRADES: {
    URL: "orgs/commodity/:id/grade/",
    METHOD: "GET",
  },
  CREATE_COMMODITY_GRADE: {
    URL: "orgs/commodity/:id/grade/",
    METHOD: "POST",
  },
  CREATE_VARIANT_GRADE_CONFIG: {
    URL: "orgs/commodity/grade/config/",
    METHOD: "POST",
  },
  ADD_VARIANT_GRADE_CONFIG_PRICE: {
    URL: "orgs/commodity/grade/config/:id/",
    METHOD: "POST",
  },
  GET_CONFIG: {
    URL: "orgs/commodity/grade/config/",
    METHOD: "GET",
  },
  SELECT_PROCURMENT: {
    URL: "orgs/commodity/details/",
    METHOD: "POST",
  },
  GET_COMMODITY_SEASONS: {
    URL: "orgs/commodity-seasons/:id",
    METHOD: "GET",
  },
  ADD_NO_GRADE_PRICE: {
    URL: "orgs/commodity/no-grade/prices/",
    METHOD: "POST",
  },
  GET_NO_GRADE_PRICE: {
    URL: "orgs/commodity/no-grade/prices/",
    METHOD: "GET",
  },
  ACTIVE_NO_GRADE_PRICE: {
    URL: "orgs/commodity/no-grade/prices/",
    METHOD: "PUT",
  },
  CONVERT_GRADES: {
    URL: "/orgs/convert-grade/",
    METHOD: "POST",
  },
  GET_CONVERTED_GRADES: {
    URL: "/orgs/convert-grade/",
    METHOD: "GET",
  },
  GET_BATCHES: {
    URL: "orgs/batch/list/",
    METHOD: "POST",
  },
  GET_BATCH_DETAILS: {
    URL: "orgs/batch/stock/:id",
    METHOD: "GET",
  },
  GET_BATCH_PRICE: {
    URL: "orgs/batch/price/",
    METHOD: "GET",
  },
  DELETE_GRADE_CONVERSION: {
    URL: "orgs/stock-adjustment/:id/",
    METHOD: "DELETE",
  },
  SUBMIT_GRADE_CONVERSION: {
    URL: "orgs/stock-adjustment/:id/",
    METHOD: "PATCH",
  },
  DOWNLOAD_PRICE_CONFIG_EXCEL: {
    URL: "orgs/generate/template/",
    METHOD: "POST",
  },
  UPLOAD_PRICE_CONFIG_EXCEL: {
    URL: "orgs/parse/config/",
    METHOD: "POST",
  },
  POST_PRICE_CONFIG_COMMODITIES: {
    URL: "orgs/add/configs/",
    METHOD: "POST",
  },
};

const commodityApi = {
  getCommodities: async (params: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_ALL,
      options: {
        queryParams: {
          ...params,
        },
      },
    });

    return response;
  },
  getCommodityDetails: async (params: string | number, id: number) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          org_id: params,
        },
      },
    });

    return response;
  },
  createCommodity: async (formData: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.CREATE,
      options: {
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },
  getCommodityGroups: async () => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_COMMODITY_GROUPS,
    });
    return response;
  },
  getAllSeasons: async () => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_SEASONS,
    });
    return response;
  },
  getGrades: async (id: number, payload?: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_GRADES,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          ...payload,
        },
      },
    });
    return response;
  },
  getCommoditySeasons: async (id: number) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_COMMODITY_SEASONS,
      options: {
        pathVars: {
          id,
        },
      },
    });
    return response;
  },
  createCommodityGrade: async (id: number, formData: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.CREATE_COMMODITY_GRADE,
      options: {
        pathVars: {
          id,
        },
        data: { ...formData },
      },
    });

    return response;
  },
  createVariantGradeConfig: async (payload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.CREATE_VARIANT_GRADE_CONFIG,
      options: {
        queryParams: {
          config_id: payload.id || "",
        },
        data: { ...payload },
      },
    });

    return response;
  },

  getConfigs: async (id: number) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_CONFIG,
      options: {
        queryParams: {
          variant_id: id,
        },
      },
    });
    return response;
  },
  addCommodityConfigGradePrice: async (payload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.ADD_VARIANT_GRADE_CONFIG_PRICE,
      options: {
        pathVars: {
          id: payload.configId,
        },
        data: { ...payload.DataToBeSend },
      },
    });

    return response;
  },
  addNoGradePrice: async (orgId: string, payload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.ADD_NO_GRADE_PRICE,
      options: {
        queryParams: {
          org_id: orgId,
        },
        data: { ...payload },
      },
    });

    return response;
  },
  getNoGradePrice: async (orgId: string, variantId: number) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_NO_GRADE_PRICE,
      options: {
        queryParams: {
          org_id: orgId,
          variant_id: variantId,
        },
      },
    });

    return response;
  },

  activeNoGradePrice: async (
    orgId: string,
    variantId: number,
    payload: any
  ) => {
    const response = await kyApiService({
      resource: API_COMMODITY.ACTIVE_NO_GRADE_PRICE,
      options: {
        queryParams: {
          org_id: orgId,
          variant_id: variantId,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  selectProcurment: async (id: string, payload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.SELECT_PROCURMENT,
      options: {
        queryParams: {
          org_id: id,
        },
        data: { ...payload },
      },
    });

    return response;
  },
  convertGrades: async (paramsPayload: any, payload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.CONVERT_GRADES,
      options: {
        queryParams: {
          ...paramsPayload,
        },
        data: { ...payload },
      },
    });

    return response;
  },
  getConvertGrades: async (paramsPayload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_CONVERTED_GRADES,
      options: {
        queryParams: {
          ...paramsPayload,
        },
      },
    });

    return response;
  },
  getBatches: async (paramsPayload: any, filterPayload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_BATCHES,
      options: {
        queryParams: {
          ...paramsPayload,
        },
        data: {
          ...filterPayload,
        },
      },
    });

    return response;
  },
  getBatcheDetails: async (paramsPayload: any, id: number | string) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_BATCH_DETAILS,
      options: {
        queryParams: {
          ...paramsPayload,
        },
        pathVars: { id },
      },
    });

    return response;
  },
  getBatchPrice: async (paramsPayload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.GET_BATCH_PRICE,
      options: {
        queryParams: {
          ...paramsPayload,
        },
      },
    });

    return response;
  },
  submitGradeConversion: async (paramsPayload: any, id: number | string) => {
    const response = await kyApiService({
      resource: API_COMMODITY.SUBMIT_GRADE_CONVERSION,
      options: {
        queryParams: {
          ...paramsPayload,
        },
        pathVars: {
          id,
        },
      },
    });

    return response;
  },
  deleteGradeConversion: async (paramsPayload: any, id: number | string) => {
    const response = await kyApiService({
      resource: API_COMMODITY.DELETE_GRADE_CONVERSION,
      options: {
        queryParams: {
          ...paramsPayload,
        },
        pathVars: {
          id,
        },
      },
    });

    return response;
  },
  downloadPriceConfigExcel: async (paramsPayload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.DOWNLOAD_PRICE_CONFIG_EXCEL,
      options: {
        queryParams: {
          ...paramsPayload,
        },
      },
    });

    return response;
  },
  parsedPriceConfigs: async (payload: object, paramsPayload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.UPLOAD_PRICE_CONFIG_EXCEL,
      options: {
        data: payload,
        queryParams: {
          ...paramsPayload,
        },
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },
  postCommoditiesPriceConfigs: async (payload: object, paramsPayload: any) => {
    const response = await kyApiService({
      resource: API_COMMODITY.POST_PRICE_CONFIG_COMMODITIES,
      options: {
        data: payload,
        queryParams: {
          ...paramsPayload,
        },
      },
    });

    return response;
  },
};

export default commodityApi;
