import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  ADD_QUESTION: {
    URL: "orgs/add-question/:id/",
    METHOD: "POST",
  },
  GET_QUESTIONS: {
    URL: "orgs/questions/:id/",
    METHOD: "GET",
  },
  CREATE_QUESTION: {
    URL: "orgs/question/",
    METHOD: "POST",
  },
  UPDATE_QUESTION: {
    URL: "orgs/question/:id/",
    METHOD: "PUT",
  },
  DELETE_QUESTION: {
    URL: "orgs/question/:id/",
    METHOD: "DELETE",
  },
};

const questionsApi = {
  getQuestions: async (
    params: string | number,
    id: string,
    filterData: object
  ) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_QUESTIONS,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          org_id: params,
          ...filterData,
        },
      },
    });

    return response;
  },
  deleteQuestion: async (params: string | number, id: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.DELETE_QUESTION,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          org_id: params,
        },
      },
    });

    return response;
  },
  addQuestion: async (SelectedQuestions: any, orgid: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.ADD_QUESTION,
      options: {
        pathVars: {
          id: orgid,
        },
        data: {
          questions: SelectedQuestions,
        },
      },
    });

    return response;
  },
  createQuestion: async (formData: any, orgid: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.CREATE_QUESTION,
      options: {
        queryParams: {
          org_id: orgid,
        },
        data: formData,
      },
    });

    return response;
  },
   updateQuestion: async (formData: any, orgid: string, questionid:string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.UPDATE_QUESTION,
      options: {
      pathVars: {
          id:questionid,
        },
        queryParams: {
          org_id: orgid,
        },
        data: formData,
      },
    });

    return response;
  },
};

export default questionsApi;
