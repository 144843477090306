import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_ATTRIBUTE: {
    URL: "orgs/attributes",
    METHOD: "GET",
  },
  CREATE_ATTRIBUTE: {
    URL: "orgs/attribute/",
    METHOD: "POST",
  }
};

const attributeApi = {
  getAllAttributes: async (pagination: object,payload: object) => {
    const response = await kyApiService({
      resource: API.GET_ATTRIBUTE,
      options: {
        queryParams: {
          ...payload,
          ...pagination
        },
      },
    });

    return response;
  },
  createAttribute: async (payload: object) => {
    const response = await kyApiService({
      resource: API.CREATE_ATTRIBUTE,
      options: {
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default attributeApi;
