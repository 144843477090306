import kyApiService from "@/infra/plugins/axios";
import { AuthApi } from "@/types/api/auth";
import { ApiResources } from "@/types/axios";

const API_ENDPOINTS: ApiResources = {
  LOGIN: {
    URL: "orgs/admin-login/",
    METHOD: "POST",
  },
  GET_OTP: {
    URL: "/user/reset/",
    METHOD: "GET",
  },
  RESET_PASSWORD: {
    URL: "/user/reset/",
    METHOD: "POST",
  },
  GET_ADDITIONAL_MODULE: {
    URL: "orgs/module/:id",
    METHOD: "GET",
  },
};

const authApi: AuthApi = {
  login: async (email: string, password: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.LOGIN,
      options: {
        data: { email, password },
        auth: false,
      },
    });

    return response;
  },
  getOTP_OnMail: async (email: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_OTP,
      options: {
        queryParams: {
          email,
        },
        auth: false,
      },
    });

    return response;
  },
  generateNewPassword: async (payload: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.RESET_PASSWORD,
      options: {
        data: {
          ...payload,
        },
        auth: false,
      },
    });

    return response;
  },
  getAdditionalModules: async (id: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ADDITIONAL_MODULE,
      options: {
        pathVars: { id },
      },
    });
    return response;
  },
};

export default authApi;
