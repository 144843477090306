import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_card = _resolveComponent("n-card")!

  return (_openBlock(), _createBlock(_component_n_card, {
    attr: _ctx.$attrs,
    style: {"min-width":"500px"}
  }, {
    cover: _withCtx(() => [
      _renderSlot(_ctx.$slots, "cover")
    ]),
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header")
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["attr"]))
}