import { markRaw } from "vue";
import { defineStore } from "pinia";

export type Modal = {
  title: string;
  subtitle: string;
  props: any;
  isOpen: boolean;
  view: object;
  actions?: ModalAction[];
  styleProps?: object | null
};

export type ModalAction = {
  label: string;
  type?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick: (props?: any) => void;
};

export const useModalStore = defineStore("modal", {
  state: (): Modal => ({
    title: "",
    subtitle: "",
    props: {},
    isOpen: false,
    view: {},
    actions: [],
    styleProps: null
  }),
  actions: {
    open(
      view: object,
      options?: {
        title: string;
        subtitle: string;
        actions?: ModalAction[];
        props?: object
        modalStyleProps?:object
      }
    ) {
      this.isOpen = true;
      this.title = options?.title || "";
      this.subtitle = options?.subtitle || "";
      this.actions = options?.actions;
      this.props = options?.props;
      // using markRaw to avoid over performance as reactive is not required
      this.view = markRaw(view);
      this.styleProps = options?.modalStyleProps
    },
    close() {
      this.isOpen = false;
      this.view = {};
      this.actions = [];
    },
    updateActions(actions: ModalAction[]) {
      this.actions = actions;
    },
  },
});
