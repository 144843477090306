
import { ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import {
  NForm,
  FormInst,
  FormValidationError,
  useMessage,
  FormRules,
  FormItemRule,
  NProgress,
} from "naive-ui";

import Input from "@/views/components/form/Input.vue";
import FormItem from "@/views/components/form/FormItem.vue";
import { useModalStore } from "@/stores/modal.store";
import { useAuthStore } from "@/stores/auth.store";

interface ModelType {
  newPassword: string | null;
  rePassword: string | null;
  otp: string | null;
}

export default {
  name: "Change Password",
  components: {
    Input,
    FormItem,
    NForm,
    NProgress,
  },
  setup(props) {
    const message = useMessage();
    const modalStore = useModalStore();
    const authStore = useAuthStore();

    const { props: modalProps } = storeToRefs(modalStore);

    const formRef = ref<FormInst | null>(null);
    const modelRef = ref<ModelType>({
      newPassword: null,
      rePassword: null,
      otp: null,
    });

    const passwordStrength: any = ref({
      indicator: "",
      value: 0,
    });

    watch(
      modelRef,
      (newVal) => {
        if (newVal.newPassword) {
          passwordStrength.value = getPasswordStrength(newVal.newPassword);
        } else {
          (passwordStrength.value.indicator = ""),
            (passwordStrength.value.value = 0);
        }
      },
      { deep: true }
    );

    function getPasswordStrength(password) {
      // Define regex patterns for weak, medium, and strong passwords
      const weakRegex = /^(?=.*[a-z]).{6,}$/;
      const mediumRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      const strongRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{10,}$/;

      if (strongRegex.test(password)) {
        return { indicator: "success", value: 80 };
      } else if (mediumRegex.test(password)) {
        return { indicator: "warning", value: 50 };
      } else if (weakRegex.test(password)) {
        return { indicator: "error", value: 20 };
      } else {
        return { indicator: "error", value: 10 };
      }
    }

    onMounted(() => {
      modalStore.updateActions([
        {
          label: "Submit",
          type: "primary",
          onClick: () => {
            submit();
          },
        },
      ]);
    });

    const rules: FormRules = {
      otp: [
        {
          required: true,
          trigger: ["blur", "input"],
          validator: (rule: FormItemRule, value: string) => {
            const otpRegex = new RegExp("^[A-Z0-9]+$");
            if (otpRegex.test(value)) {
              return true;
            } else {
              return new Error("Invalid OTP");
            }
          },
        },
      ],
    };

    const submit = () => {
      formRef.value?.validate(
        (errors: Array<FormValidationError> | undefined) => {
          if (!errors) {
            if (
              modelRef.value.newPassword &&
              modelRef.value.otp &&
              modalProps.value.userEmail
            ) {
              if (modelRef.value.newPassword !== modelRef.value.rePassword) {
                message.error("Password does not match");
                return;
              }
              authStore.generateNewPassword({
                new_password: modelRef.value.newPassword,
                confirm_password: modelRef.value.rePassword,
                email: modalProps.value.userEmail,
                otp: modelRef.value.otp,
              });
            }
          } else {
            console.log(errors);
            message.error("Invalid Data");
          }
        }
      );
    };

    return {
      formRef,
      rules,
      model: modelRef,
      submit,
      passwordStrength,
    };
  },
};
