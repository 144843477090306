import kyApi from "@/infra/services/api";
import { defineStore } from "pinia";
declare const window: any;
import { useCommodityStore } from "./commodity.store";
import { useModalStore } from "./modal.store";

interface User {
  email: string;
  name: string;
  orgId: string;
  roles: string[];
  profiles: any[];
}

interface AuthState {
  user: User | null;
  commodities: any;
  isAuthenticated: boolean;
  openOTPModal: boolean;
  token: string | null;
  allowedModules: any[];
  error: {
    login: string | null;
    getOTP_OnMail: string | null;
    generateNewPassword: string | null;
    getAllowedModules: string | null;
  };
  loading: {
    login: boolean;
    getOTP_OnMail: boolean;
    generateNewPassword: boolean;
    getAllowedModules: boolean;
  };
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => ({
    user: null,
    commodities: [],
    openOTPModal: false,
    isAuthenticated: false,
    token: null,
    allowedModules: [],
    error: {
      login: null,
      getOTP_OnMail: null,
      generateNewPassword: null,
      getAllowedModules: null,
    },
    loading: {
      login: false,
      getOTP_OnMail: false,
      generateNewPassword: false,
      getAllowedModules: false,
    },
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
  },
  actions: {
    async login(email: string, password: string) {
      const commodityStore = useCommodityStore();

      this.loading.login = true;
      const response = await kyApi.auth.login(email, password);

      if (response.error) {
        this.error.login = response.message;
        window.$message.error(response.message);
      } else {
        this.user = {
          email: response.data.email,
          name: response.data.name,
          orgId: response.data.org_id,
          roles: response.data.roles,
          profiles: response.data.profiles,
        };
        this.token = response.data.access_token;
        this.isAuthenticated = true;
        this.allowedModules = response.data.enabled_modules.map(
          (module) => module.name
        );
        commodityStore.getCommodities();
      }

      console.log(this.isAuthenticated);

      this.loading.login = false;
    },
    async getOTP_OnMail(email: string) {
      this.openOTPModal = false;
      this.loading.getOTP_OnMail = true;
      const response = await kyApi.auth.getOTP_OnMail(email);

      if (response.error) {
        this.error.getOTP_OnMail = response.message;
        window.$message.error(response.message);
      } else {
        window.$message.success(response.message);
        this.openOTPModal = true;
      }
      this.loading.getOTP_OnMail = false;
    },
    async generateNewPassword(payload: any) {
      const modalStore = useModalStore();

      this.loading.generateNewPassword = true;
      const response = await kyApi.auth.generateNewPassword(payload);

      if (response.error) {
        this.error.generateNewPassword = response.message;
        window.$message.error(response.message);
      } else {
        window.$message.success(response.message);
        modalStore.close();
      }
      this.loading.generateNewPassword = false;
    },

    async getAllowedModules(orgID: number) {
      this.loading.getAllowedModules = true;
      const response = await kyApi.auth.getAdditionalModules(orgID);

      if (response.error) {
        this.error.getAllowedModules = response.message;
        window.$message.error(response.message);
      } else {
        this.allowedModules = response.data
          .filter((module) => module.status === "ACTIVE")
          .map((mod) => mod.name);
      }
      this.loading.getAllowedModules = false;
    },

    updateModules() {
      this.allowedModules = ["INSURANCE", "POP", "EXTENSION_SERVICE"];
    },
    logout() {
      this.user = null;
      this.token = null;
      this.isAuthenticated = false;
      this.router.push("login");
    },
  },
  persist: {
    enabled: true,
  },
});
