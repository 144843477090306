
import { useMessage } from "naive-ui";
import { defineComponent } from "vue";
declare const window: any;

// content
export default defineComponent({
  name: "Content",
  setup() {
    window.$message;
    window.$message = useMessage();
  },
});
