import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL_ITEMS: {
    URL: "orgs/items/",
    METHOD: "POST",
  },
  GET_LEDGER: {
    URL: "orgs/item/:id/ledger/",
    METHOD: "POST",
  },
  GET_PROCESSOR_INVENTORY_ITEMS: {
    URL: "orgs/processor/items/",
    METHOD: "POST",
  },
  GET_PROCESSOR_INVENTORY_ITEMS_DETAILS: {
    URL: "orgs/processor/item/:id/",
    METHOD: "POST",
  },
};

const inventoryApi = {
  getInventoryItems: async (filterData: object, pagination: object) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_ITEMS,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...filterData,
        },
      },
    });
    return response;
  },
  getProcessorInventoryItems: async (payload: any, orgId: string, pagination) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_PROCESSOR_INVENTORY_ITEMS,
      options: {
        queryParams: {
          org_id: orgId,
          ...pagination
        },
        data: {
          ...payload,
        },
      },
    });
    return response;
  },
  getProcessorInventoryItemsDetails: async (
    payload: any,
    filtersPayload: any,
    orgId: string
  ) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_PROCESSOR_INVENTORY_ITEMS_DETAILS,
      options: {
        queryParams: {
          org_id: orgId,
          processor_id: payload.processor_id,
        },
        pathVars: {
          id: payload.id,
        },
        data: {
          ...filtersPayload,
        },
      },
    });
    return response;
  },
  getItemLedger: async (filterData: object, id: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_LEDGER,
      options: {
        pathVars: {
          id,
        },
        data: {
          ...filterData,
        },
      },
    });
    return response;
  },
};

export default inventoryApi;
