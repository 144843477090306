import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_YEARS: {
    URL: "orgs/years/",
    METHOD: "GET",
  },
  GET_TIMELINE: {
    URL: "orgs/timeline/",
    METHOD: "POST",
  },
};

const timelineApi = {
  getYears: async (payload: any, farmerID: number) => {
    const response = await kyApiService({
      resource: API.GET_YEARS,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });

    return response;
  },
  farmerTimeline: async (payload: object, dataPayload: any) => {
    const response = await kyApiService({
      resource: API.GET_TIMELINE,
      options: {
        queryParams: {
          ...payload,
        },
        data: {
          ...dataPayload,
        },
      },
    });

    return response;
  },
};

export default timelineApi;
