import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_CONSIGNMENTS: {
    URL: "orgs/consignments/",
    METHOD: "POST",
  },
  GET_CONSIGNMENT_DETAILS: {
    URL: "orgs/consignment/:id/",
    METHOD: "GET",
  },
  ADD_CONSIGNMENT: {
    URL: "orgs/consignment/",
    METHOD: "POST",
  },
  ADD_CONSIGNMENT_DOCS: {
    URL: "orgs/documents/",
    METHOD: "POST",
  },
  GET_CONSIGNMENT_DOCS: {
    URL: "orgs/document/:id/",
    METHOD: "GET",
  },
  DELETE_DOC: {
    URL: "orgs/document/:id/",
    METHOD: "DELETE",
  },
};

const consignmentApi = {
  getConsignments: async (orgId: string, pagination: object, payload: any) => {
    const response = await kyApiService({
      resource: API.GET_CONSIGNMENTS,
      options: {
        queryParams: {
          org_id: orgId,
          ...pagination,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  getConsignmentDetails: async (orgId: string, consignmentId: number) => {
    const response = await kyApiService({
      resource: API.GET_CONSIGNMENT_DETAILS,
      options: {
        queryParams: {
          org_id: orgId,
        },
        pathVars: {
          id: consignmentId,
        },
      },
    });

    return response;
  },
  getConsignmentDocs: async (orgId: string, consignmentId: number) => {
    const response = await kyApiService({
      resource: API.GET_CONSIGNMENT_DOCS,
      options: {
        // queryParams: {
        //   org_id: orgId,
        // },
        pathVars: {
          id: consignmentId,
        },
      },
    });

    return response;
  },
  addConsignment: async (payload: object) => {
    const response = await kyApiService({
      resource: API.ADD_CONSIGNMENT,
      options: {
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  addConsignmentDocs: async (payload: object) => {
    const response = await kyApiService({
      resource: API.ADD_CONSIGNMENT_DOCS,
      options: {
        data: payload,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },
  deleteConsignmentDoc: async (docId: number) => {
    const response = await kyApiService({
      resource: API.DELETE_DOC,
      options: {
        pathVars: {
          id: docId,
        },
      },
    });

    return response;
  },
};

export default consignmentApi;
