import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_INCENTIVE: {
    URL: "/orgs/incentives/",
    METHOD: "GET",
  },
  ADD_INCENTIVE: {
    URL: "/orgs/incentives/",
    METHOD: "POST",
  },
  GET_INCENTIVE_LEDGER: {
    URL: "/orgs/incentive-ledger/:id/",
    METHOD: "GET",
  },
  UPDATE_INCENTIVE: {
    URL: "/orgs/incentive/",
    METHOD: "PUT",
  },
};

const incentiveApi = {
  getIncentives: async (commodityId: number, orgId: string) => {
    const response = await kyApiService({
      resource: API.GET_INCENTIVE,
      options: {
        queryParams: {
          commodity_id: commodityId,
          org_id: orgId,
        },
      },
    });

    return response;
  },
  getIncentiveLedger: async (
    executiveId: number,
    orgId: string,
    pagination: any
  ) => {
    const response = await kyApiService({
      resource: API.GET_INCENTIVE_LEDGER,
      options: {
        pathVars: {
          id: executiveId,
        },
        queryParams: {
          org_id: orgId,
          ...pagination,
        },
      },
    });

    return response;
  },
  addIncentive: async (payload: object, orgId: string) => {
    const response = await kyApiService({
      resource: API.ADD_INCENTIVE,
      options: {
        queryParams: {
          org_id: orgId,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  updateIncentive: async (payload: object, orgId: string) => {
    const response = await kyApiService({
      resource: API.UPDATE_INCENTIVE,
      options: {
        queryParams: {
          org_id: orgId,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default incentiveApi;
