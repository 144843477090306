import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"16px","width":"50%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_n_progress = _resolveComponent("n-progress")!
  const _component_n_form = _resolveComponent("n-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_n_form, {
      class: "form",
      model: $setup.model,
      ref: "formRef",
      rules: $setup.rules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormItem, {
          path: "newPassword",
          label: "New Password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Input, {
              value: $setup.model.newPassword,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.model.newPassword) = $event)),
              type: "password",
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_n_progress, {
            type: "line",
            status: $setup.passwordStrength.indicator,
            percentage: $setup.passwordStrength.value,
            "show-indicator": false
          }, null, 8, ["status", "percentage"])
        ]),
        _createVNode(_component_FormItem, {
          path: "rePassword",
          label: "Re-Enter Password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Input, {
              value: $setup.model.rePassword,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.model.rePassword) = $event)),
              type: "password",
              onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_FormItem, {
          path: "otp",
          label: "OTP"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Input, {
              value: $setup.model.otp,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.model.otp) = $event)),
              onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}