import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL_STATES: {
    URL: "orgs/states/",
    METHOD: "GET",
  },
  GET_DISTRICTS: {
    URL: "orgs/districts/",
    METHOD: "GET",
  },
  GET_STATES_AND_CITY: {
    URL: "orgs/pincode/",
    METHOD: "GET",
  },
};

const regionApi = {
  getDistrict: async (state: string) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_DISTRICTS,
      options: {
       queryParams: {
        state,
       }
      },
    });

    return response;
  },
  getStatesList: async () => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_STATES,
      options: {
       queryParams: {
        expand: 0
       }
      },
    });

    return response;
  },
  getStateAndCity: async (params: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_STATES_AND_CITY,
      options: {
       queryParams: {
        ...params
       },
      },
    });

    return response;
  },
};


export default regionApi;