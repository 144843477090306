/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import type { ApiService, ProcessApiProps } from "@/types/axios";
import { useAuthStore } from "@/stores/auth.store";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "sub-domain": window.location.hostname.split(".")[0],
  },
});

axiosInstance.interceptors.request.use(
  async (request) => {
    if (request.headers) {
      const verifyRequest = request.headers["x-verify-request"];
      delete request.headers["x-verify-request"];

      if (!verifyRequest) {
        return request;
      }

      const authStore = useAuthStore();
      const accessToken = authStore.getToken;

      if (!accessToken) throw Error("no access token found");
      // user should logout

      request.headers["Authorization"] = accessToken;
      return request;
    }

    return request;
  },
  (err) => Promise.reject(err)
);

// >>>>>> Gold Api Service <<<<<<<<<<
export const kyApiService: ApiService = async ({
  resource,
  options = {},
  headers,
}) => {
  const { url, method, data } = processApiProps({ resource, options });

  const auth = true;

  // log(`[goldApi] 📤 : ${method} -> ${url}`, data);
  let response: any;

  try {
    response = await axiosInstance.request({
      url,
      method,
      data,
      headers: {
        ...headers,
        "x-verify-request": "auth" in options ? options.auth : auth,
      },
    });

    // log("[goldApi] 📥  Success: ", returnObject);

    return response.data;
  } catch (err: any) {
    console.log("[Api] 📥  Error: ", err);

    return {
      ...err.response.data,
    };
  }
};

// >>>>>> Gold Api Helper Functions <<<<<<<<<<

const processApiProps: ProcessApiProps = ({ resource, options = {} }) => {
  let url = resource.URL;
  const method = resource.METHOD;

  // Replace path vars with their value if any
  const { pathVars } = options;

  if (pathVars) {
    Object.keys(pathVars).forEach(
      (key) => (url = url.replace(`:${key}`, `${pathVars[key]}`))
    );
  }

  // Add queryParams if any
  const { queryParams } = options;
  if (queryParams) {
    url += `?a=1`;
    const queryParamsKey = Object.keys(queryParams);
    if (queryParamsKey.length) {
      url += `&${queryParamsKey
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&")}`;
    }
  }

  return {
    url,
    method,
    data: options.data,
  };
};

export default kyApiService;
