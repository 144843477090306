import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_card = _resolveComponent("n-card")!

  return (_openBlock(), _createBlock(_component_n_card, {
    attr: _ctx.$attrs,
    class: "card-wrap"
  }, {
    cover: _withCtx(() => [
      _renderSlot(_ctx.$slots, "cover", {}, undefined, true)
    ]),
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["attr"]))
}