import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!

  return (_openBlock(), _createBlock(_component_n_input, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
    prefix: _withCtx(() => [
      _renderSlot(_ctx.$slots, "prefix")
    ]),
    suffix: _withCtx(() => [
      _renderSlot(_ctx.$slots, "suffix")
    ]),
    _: 3
  }, 16))
}