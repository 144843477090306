import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_REQUESTS: {
    URL: "orgs/user-delete/requests/",
    METHOD: "GET",
  },
  CREATE_DELETE_REQUEST: {
    URL: "orgs/request/user/delete/",
    METHOD: "POST",
  },
  GET_OTP: {
    URL: "orgs/generate-otp/",
    METHOD: "POST",
  },
};

const requestApi = {
  getOTP: async (payload: any) => {
    const response = await kyApiService({
      resource: API.GET_OTP,
      options: {
        data: {
          ...payload,
        },
        auth: false,
      },
    });

    return response;
  },
  createDeleteRequest: async (payload: object) => {
    const response = await kyApiService({
      resource: API.CREATE_DELETE_REQUEST,
      options: {
        data: {
          ...payload,
        },
        auth: false,
      },
    });

    return response;
  },
  getDeleteRequests: async (payload: object) => {
    const response = await kyApiService({
      resource: API.GET_REQUESTS,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default requestApi;
