import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL_DEDUCTIONS: {
    URL: "orgs/deductions",
    METHOD: "POST",
  },
};

const deductionApi = {
  getDeductions: async (pagination: any, payload: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_DEDUCTIONS,
      options: {
        queryParams: {
          ...pagination,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default deductionApi;
