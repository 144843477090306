import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Button = _resolveComponent("Button")!
  const _component_n_form = _resolveComponent("n-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_n_form, {
      class: "form",
      model: $setup.model,
      ref: "formRef",
      rules: $setup.rules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormItem, {
          path: "email",
          label: "Email"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Input, {
              value: $setup.model.email,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.model.email) = $event)),
              type: "email",
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_FormItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "mr-4",
              type: "success",
              onClick: $setup.submitForm
            }, {
              default: _withCtx(() => [
                _createTextVNode("Submit")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}