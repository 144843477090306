import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.modalProps, (error, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        style: {"display":"flex","flex-direction":"column","margin":"4px"}
      }, [
        _createElementVNode("span", null, _toDisplayString(error), 1)
      ]))
    }), 128))
  ]))
}