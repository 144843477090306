
import { NModal } from "naive-ui";
import { useModalStore } from "@/stores/modal.store";
import { reactive } from "vue";
import { storeToRefs } from "pinia";
import Button from "./Button/Button.vue";
import Card from "./Card.vue";

export default {
  name: "Modal",
  components: {
    Button,
    NModal,
    Card,
  },
  setup() {
    // get the modal store
    const modal = useModalStore();

    // reactive container to save the payload returned by the mounted view
    const model = reactive({});

    // convert all state properties to reactive references to be used on view
    const { isOpen, view, actions, title, subtitle, styleProps} = storeToRefs(modal);

    return {
      isOpen,
      view,
      actions,
      model,
      title,
      subtitle,
      styleProps,
      bodyStyle: {
        width: "700px",
      },
    };
  },
};
