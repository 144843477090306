import "@mdi/font/css/materialdesignicons.css";
import { createApp, markRaw } from "vue";
import { createPinia, storeToRefs } from "pinia";
import piniaPersist from "pinia-plugin-persist";
// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import App from "./App.vue";
import router from "./router";
import { Router } from "vue-router";
import { useAuthStore } from "./stores/auth.store";
import ModulePermissions from "./config/permissions";
import useCommodityStore from "./stores/commodity.store";
import { useModalStore } from "@/stores/modal.store";

import CommodityFieldErrorMessages from "@/views/pages/commodity/forms/CommodityFieldErrorMessages.vue";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});
const app = createApp(App);
const pinia = createPinia();
declare module "pinia" {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

pinia.use(({ store }) => {
  store.router = markRaw(router);
});
pinia.use(piniaPersist);
app.use(pinia);
app.use(vuetify);

router.beforeEach((to, from, next) => {
  const store = useAuthStore();
  const commodityStore = useCommodityStore();
  const modal = useModalStore();

  const { isAuthenticated, user } = storeToRefs(store);
  const { commodities } = storeToRefs(commodityStore);

  if (to.meta.requiresAuth && !isAuthenticated.value) next("/login");

  // get user role from storage

  const userRole = user.value?.roles || [];
  // const userRole = ["SALES_MANAGER", "WAREHOUSE_MANAGER"];

  // get user permissions

  const aggregatedData = {};
  for (const role of userRole) {
    const userData = ModulePermissions[role];

    // Iterate through the permissions of the current user
    for (const category in userData) {
      if (!aggregatedData[category]) {
        aggregatedData[category] = [];
      }
      aggregatedData[category].push(...userData[category]);
    }
  }

  // const userPermissions = ModulePermissions[userRole || ""];

  const userPermissions = aggregatedData;
  // pass permissions to route in meta
  to.meta.permissions = userPermissions;

  const tempError: any = [];
  function logErrors(data) {
    data.forEach((item) => {
      if (item.error?.length > 0) {
        item.error.forEach((error) => {
          const key = Object.keys(error)[0];
          const message = error[key];
          tempError.push(`${key}: ${message}`);
        });
      }
    });
  }
  logErrors(commodities.value);

  if (tempError.length) {
    if (to.path === "/commodities" || to.name === "Commodities") {
      next(); // do not trigger the beforeEach hook again
    } else if (to.path.includes("commodity") || to.path.includes("variant")) {
      next();
    } else {
      if (tempError.length) {
        modal.open(CommodityFieldErrorMessages, {
          title: "Please fill the fields",
          subtitle: "Please fill the fields",
          props: tempError,
        });
      }
      next("/commodities");
    }
  } else {
    next();
  }

  // next();
});

app.use(router).mount("#app");
