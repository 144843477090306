import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ACCOUNT: ApiResources = {
  GET_CHILD_OF_TREE: {
    URL: "orgs/child-of-tree",
    METHOD: "GET",
  },
  GET_REPORT: {
    URL: "orgs/report",
    METHOD: "POST",
  },
  GET_BULK_PAYMENTS: {
    URL: "orgs/agent/payment/",
    METHOD: "GET",
  },
  UPLOAD_BULK_PAYMENTS: {
    URL: "orgs/agent/payment/",
    METHOD: "POST",
  },
};

const accountApi = {
  getChildOfTree: async (payload: object) => {
    const response = await kyApiService({
      resource: API_ACCOUNT.GET_CHILD_OF_TREE,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });

    return response;
  },
  getReports: async (payload: object) => {
    const response = await kyApiService({
      resource: API_ACCOUNT.GET_REPORT,
      options: {
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  getBulkPayments: async (payload: object, orgId: any) => {
    const response = await kyApiService({
      resource: API_ACCOUNT.GET_BULK_PAYMENTS,
      options: {
        queryParams: {
          org_id: orgId,
          ...payload,
        },
      },
    });

    return response;
  },
  uploadBulkPayments: async (payload: object) => {
    const response = await kyApiService({
      resource: API_ACCOUNT.UPLOAD_BULK_PAYMENTS,
      options: {
        data: payload,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },
};

export default accountApi;
