import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_BANK_DETAILS: {
    URL: "/orgs/bank/details/:id/",
    METHOD: "GET",
  },
};

const bankApi  = {
  getBankDetails: async (id: number) => {
    const response = await kyApiService({
      resource: API.GET_BANK_DETAILS,
      options: {
        pathVars: {
          id
        },
      },
    });

    return response;
  },
};

export default bankApi;
