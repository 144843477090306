import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_MEDIA: {
    URL: "/orgs/media/",
    METHOD: "POST",
  },
};

const mediaApi = {
  getMediaLink: async (payload: object) => {
    const response = await kyApiService({
      resource: API.GET_MEDIA,
      options: {
        data: payload,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },
};

export default mediaApi;
