import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_POP: {
    URL: "orgs/packages/",
    METHOD: "GET",
  },
  GET_POP_DETAILS: {
    URL: "orgs/package/:id/",
    METHOD: "GET",
  },
  ASSIGN_POP: {
    URL: "orgs/package/:id/",
    METHOD: "PUT",
  },
  ADD_POP: {
    URL: "orgs/packages/",
    METHOD: "POST",
  },
  UPDATE_POP: {
    URL: "orgs/update/package/:id/",
    METHOD: "PUT",
  },
  UPLOAD_POP: {
    URL: "orgs/process/file/",
    METHOD: "POST",
  },
  GET_ASSIGNED_POP: {
    URL: "orgs/assigned/packages/",
    METHOD: "GET",
  },
  GET_ASSIGNED_POP_DETAILS: {
    URL: "orgs/assigned/package/:id/",
    METHOD: "GET",
  },
  GET_POP_ASSIGNED_CROPS : {
    URL: "orgs/crops/package/",
    METHOD: "GET",
  }
};

const POPApi = {
  getAllPOP: async (pagination: object) => {
    const response = await kyApiService({
      resource: API.GET_POP,
      options: {
        queryParams: {
          ...pagination,
        },
      },
    });

    return response;
  },
  getPOPDetails: async (popId: number, payload: object) => {
    const response = await kyApiService({
      resource: API.GET_POP_DETAILS,
      options: {
        pathVars: {
          id: popId,
        },
        queryParams: {
          ...payload,
        },
      },
    });

    return response;
  },
  assignPOP: async (popId: number, payload: object, paramsPayload: any) => {
    const response = await kyApiService({
      resource: API.ASSIGN_POP,
      options: {
        pathVars: {
          id: popId,
        },
        data: {
          ...payload,
        },
        queryParams: {
          ...paramsPayload,
        },
      },
    });

    return response;
  },
  createPOP: async (payload: object, paramsPayload: any) => {
    const response = await kyApiService({
      resource: API.ADD_POP,
      options: {
        queryParams: {
          ...paramsPayload,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  updatePOPPackage: async (id: number, payload: object, paramsPayload: any) => {
    const response = await kyApiService({
      resource: API.UPDATE_POP,
      options: {
        pathVars: {
          id,
        },
        queryParams: {
          ...paramsPayload,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
  uploadPOP: async (payload: object) => {
    const response = await kyApiService({
      resource: API.UPLOAD_POP,
      options: {
        data: payload,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },
  getAssignedPOPs: async (payload: object) => {
    const response = await kyApiService({
      resource: API.GET_ASSIGNED_POP,
      options: {
        queryParams: { ...payload },
      },
    });

    return response;
  },
  getAssignedPOPDetails: async (id: number, payload: object) => {
    const response = await kyApiService({
      resource: API.GET_ASSIGNED_POP_DETAILS,
      options: {
        queryParams: { ...payload },
        pathVars: {
          id,
        },
      },
    });

    return response;
  },
  getPOPAssignedCrops: async (payload: object) => {
    const response = await kyApiService({
      resource: API.GET_POP_ASSIGNED_CROPS,
      options: {
        queryParams: { ...payload },
      },
    });

    return response;
  },
};

export default POPApi;
