import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API_ENDPOINTS: ApiResources = {
  GET_ALL_INSURANCES: {
    URL: "orgs/insurance/",
    METHOD: "GET",
  },
  GET_INSURANCE_DETAILS: {
    URL: "orgs/insurance/:id",
    METHOD: "GET",
  },
};

const insuranceApi = {
  getInsurances: async (orgId: string, pagination: any) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_ALL_INSURANCES,
      options: {
        queryParams: {
          org_id: orgId,
          ...pagination,
        },
      },
    });

    return response;
  },
  getInsuranceDetails: async (id: number) => {
    const response = await kyApiService({
      resource: API_ENDPOINTS.GET_INSURANCE_DETAILS,
      options: {
        pathVars: {
          id,
        },
      },
    });

    return response;
  },
};

export default insuranceApi;
