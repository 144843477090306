import { ApiResources } from "@/types/axios";
import kyApiService from "@/infra/plugins/axios";

const API: ApiResources = {
  GET_PRODUCTS: {
    URL: "/orgs/processed/products/",
    METHOD: "GET",
  },
  ADD_PRODUCTS: {
    URL: "/orgs/processed/products/",
    METHOD: "POST",
  },
  GET_PRODUCT_DETAILS: {
    URL: "orgs/processed/product/:id",
    METHOD: "GET",
  },
};

const processedApi = {
  getProducts: async (orgId: string) => {
    const response = await kyApiService({
      resource: API.GET_PRODUCTS,
      options: {
        queryParams: {
          org_id: orgId,
        },
      },
    });

    return response;
  },
  getProductDetails: async (id: any) => {
    const response = await kyApiService({
      resource: API.GET_PRODUCT_DETAILS,
      options: {
        pathVars: {
          id,
        },
      },
    });

    return response;
  },
  addProducts: async (orgId: string, payload: object) => {
    const response = await kyApiService({
      resource: API.ADD_PRODUCTS,
      options: {
        queryParams: {
          org_id: orgId,
        },
        data: {
          ...payload,
        },
      },
    });

    return response;
  },
};

export default processedApi;
