const RolesEnum = Object.freeze({
  SUPER_ADMIN: "SUPER_ADMIN",
  ORG_ADMIN: "ORG_ADMIN",
  WAREHOUSE_MANAGER: "WAREHOUSE_MANAGER",
  SALES_MANAGER: "SALES_MANAGER",
  ACCOUNTS_MANAGER: "ACCOUNTS_MANAGER",
  ENGAGEMENT_MANAGER: "ENGAGEMENT_MANAGER",
  PROCUREMENT_MANAGER: "PROCUREMENT_MANAGER",
  JUNIOR_AGRONOMIST: "JUNIOR_AGRONOMIST",
  SENIOR_AGRONOMIST: "SENIOR_AGRONOMIST",
});

const ModulesEnum = Object.freeze({
  DASHBOARD: "Dashboard",
  ORGANISATIONS: "Organisations",
  COMMODITIES: "Commodities",
  HARVEST: "Harvest",
  FARMERS: "Farmers",
  EXECUTIVES: "Executives",
  MANAGERS: "Managers",
  PURCHASES: "Purchases",
  INVENTORY: "Inventory",
  SALES: "Sales",
  ACCOUNTS: "Accounts",
  USERS: "Users",
  BUYERS: "Buyers",
  BUYERS_CUSTOMERS: "Buyers Customers",
  BUYERS_REQUIREMENTS: "Buyers Requirements",
  // BUYERS_INTERESTS: "Buyers Interests",
  PAYMENTS: "Payments",
  DEDUCTIONS: "Deductions",
  SALES_CUSTOMERS: "Sales Customers",
  SALES_INVOICES: "Invoices",
  CONSIGNMENTS: "Consignments",
  TRANSPORTS: "Transports",
  WAREHOUSES: "Warehouses",
  STOCK_ADJUSTMENTS: "Stock Adjustments",
  STOCK_TRANSFERS: "Stock Transfers",
  STOCK_LEDGER: "Stock Ledger",
  PROFILE: "Profile",
  CHART_OF_ACCOUNT: "Chart of Accounts",
  REPORTS: "Reports",
  CROPS: "Crops Calender",
  ATTRIBUTES: "Grade Attributes",
  LIVETRACKING: "Live Tracking",
  BULK_PAYMENTS: "Bulk Payments",
  DEFINE_PRODUCT: "Define Products",
  PURCHASE_ORDERS: "Purchase Orders",
  BRAND_PURCHASE_ORDERS: "Brand Purchase Orders",
  PROCESSOR_INVENTORY: "Processor Inventory",
  PROCESSOR: "Processor",
  PROCESSOR_CONSIGNMENTS: "Consignments To Brand",
  PROCESSOR_SALES_INVOICE: "Processor Sales Invoice",
  PROCESSOR_CUSTOMERS: "Processors (Customers)",
  PROCESSOR_SALES_INVOICES: "Processor sales Invoice",
  PROCESSOR_REQUIREMENTS: "Processor Requirements",
  BRAND_REQUIREMENTS: "Brand Requirements",
  INSURANCE: "Insurance",
  PACKAGING: "Packaging",
  CROP_NUTRITION: "Crop Nutrition",
  PESTICIDES: "Pesticides",
  FERTILIZERS: "Fertilizers",
  PACKAGE_OF_PRACTICE: "Package of Practice",
  COMPLAINT_TICKET: "Complaint Ticket",
  JUNIOR_AGRONOMISTS: "Agronomists",
  ASSIGNED_PACKAGE_OF_PRACTICE: "Assigned Package of Practice",
  TASKS: "Tasks",
  POP_CROPS: "POP Assigned Crops",
  GRADE_CONVERTION: "Grade Conversion",
  BATCH_PL: "Batchwise P&L",
  ACCOUNT_DELETE_REQUESTS: "Account Delete Requests"
});

const PermissionIdEnum = Object.freeze({
  [ModulesEnum.ORGANISATIONS]: {
    CREATE_NEW_ORGANISATION: "create-new-organization",
    EDIT_ORGANISATION: "edit-organization",
    ADD_COMMODITY: "add-commodity",
    VISIT_ERP_SITE: "visit-erp-site",
    ADD_CDMS: "add-cdms",
  },
  [ModulesEnum.COMMODITIES]: {
    ADD_COMMODITY: "add-commodity",
    ADD_VARIANT: "add-variant",
    CREATE_NEW_QUESTION: "create-new-question",
    ADD_FROM_QUESTION_BANK: "add-from-question-bank",
    DELETE_QUESTION: "delete-question",
    CREATE_NEW_COMMODITY: "create-new-commodity",
  },
  [ModulesEnum.HARVEST]: {
    CREATE_NEW_USER: "create-new-user",
    REASSIGN_LEADS: "reassign-leads",
    DOWNLOAD_CSV: "download-csv",
    DELETE_LEAD: "delete-lead",
    SUBMIT_HARVEST: "submit-harvest",
  },
  [ModulesEnum.FARMERS]: {
    DOWNLOAD_CSV: "download-csv",
    DELETE_HARVEST: "delete-harvest",
  },
  [ModulesEnum.EXECUTIVES]: {
    PROMOTE_EXECUTIVES: "promote-executives",
    REASSIGN_EXECUTIVES: "reassign-executives",
    DOWNLOAD_CSV: "download-csv",
    ACTIVATE_EXECUTIVE: "activate-executive",
    DEACTIVATE_EXECUTIVE: "deactivate-executive",
    DELETE_LEAD: "delete-lead",
  },
  [ModulesEnum.PURCHASES]: {
    PAY_NOW: "pay-now",
    DOWNLOAD_CSV_PURCHASE: "download-csv",
  },
  [ModulesEnum.INVENTORY]: {
    DELETE_STOCK_ADJUSTMENT: "delete-stock-adjustment",
    SUBMIT_STOCK_ADJUSTMENT: "submit-stock-adjustment",
    CREATE_STOCK_ADJUSTMENT: "create-stock-adjustment",
    CREATE_STOCK_TRANSFER: "create-stock-transfer",
    CREATE_WAREHOUSE: "create-warehouse",
    EDIT_WAREHOUSE: "edit-warehouse",
    DOWNLOAD_CSV_INVENTORY: "download-csv",
  },
  [ModulesEnum.SALES]: {
    CREATE_CUSTOMER: "create-customer",
    EDIT_CUSTOMER: "edit-customer",
    CREATE_SALES_INVOICE: "create-sales-invoice",
    ADD_TRANSPORT: "add-transport",
    SUBMIT_SALES_INVOICE: "submit-sales-invoice",
    DOWNLOAD_CSV_TRANSPORT: "download-csv",
    DOWNLOAD_CSV_INVOICE: "download-csv-invoice",
  },
  [ModulesEnum.ACCOUNTS]: {
    TRANSFER_FUNDS_INTERNALLY: "transfer-funds-internally",
    DOWNLOAD_BULK_PAYMENTS: "download-bulk-payments",
    UPLOAD_BULK_PAYMENT: "upload-bulk-payments",
  },
  [ModulesEnum.TRANSPORTS]: {
    ADD_TRANSPORT: "add-transport",
    DOWNLOAD_CSV_TRANSPORT: "download-csv",
  },
  [ModulesEnum.PURCHASE_ORDERS]: {
    ADD_TRANSPORT: "add-transport",
    DOWNLOAD_CSV_PO: "download-csv-po",
  },
  [ModulesEnum.CONSIGNMENTS]: {
    DOWNLOAD_CSV_CONSIGNMENTS: "download-csv",
    CREATE_CONSIGNMENT: "create-consignment",
  },
  [ModulesEnum.COMPLAINT_TICKET]: {
    CHANGE_TICKET_STATUS: "change-status",
    ASSIGN_TICKET: "assign-ticket",
    COMMENT_ON_TICKET: "comment-ticket",
    RESPOND_TO_TICKET: "respond-to-ticket",
  },
  [ModulesEnum.PACKAGE_OF_PRACTICE]: {
    EDIT_POP: "edit-pop",
    DOWNLOAD_POP: "download-pop",
    UPLOAD_POP: "upload-pop",
  },
});

const UserSidebarMenuTypeEnum = Object.freeze({
  CLIENT: "client",
  ADMIN: "admin",
});

const QuestionTypesEnum = Object.freeze({
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  OPTIONS: "OPTIONS",
  QUESTION_GROUP: "QUESTION_GROUP",
  MEDIA: "MEDIA",
  DATE: "DATE",
});

const FilteredLeadStatusEnum = Object.freeze({
  CREATED: "CREATED",
  READY_FOR_APPROVAL: "READY_FOR_APPROVAL",
  REVIEW_SCHEDULE: "REVIEW_SCHEDULE",
  REVIEW_HARVEST: "REVIEW_HARVEST",
});

export {
  RolesEnum,
  ModulesEnum,
  PermissionIdEnum,
  UserSidebarMenuTypeEnum,
  QuestionTypesEnum,
  FilteredLeadStatusEnum,
};
