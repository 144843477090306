
import { defineComponent } from "vue";
import { NButton, NIcon} from "naive-ui";

export default defineComponent({
  props: {
    icon: Boolean
  },
  components: {
    NButton,
    NIcon,
  },
});
